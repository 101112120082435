import React, { useEffect, useContext, useMemo } from "react"
import getValue from "get-value"
import { isEqual, omit } from "lodash"
import { useLineItems } from "@context/shopify-context-provider"
import { graphql, useStaticQuery } from "gatsby"
import UpsellItem from './item'
import { getPriceFromVariantPresentmentPriceNodes } from '@utils/currency'
import { isVisibleIn, mapSizeValue } from "@utils/region"
import Slider from 'react-slick'
// import * as styles from './upsell.module.css'
import { filterUpsellsByAvailability, variantIdFromShopifyId } from "@utils/product"
import { ReactComponent as ArrowDown } from "@svgs/arrow-down.svg";

interface LineItemMap {
  variantId: string
  productId: string
}

const SliderArrowComponent = ({ rotate = false, onClick }) => {
	const classList = `absolute z-10 p-2 right-[10px] bg-soft-black flex justify-end top-1/4 -translate-y-1/4 cursor-pointer rounded-md ${rotate ? 'right-[45px]' : ''}`
	return (
		<div onClick={onClick} className={classList} tabIndex={0} aria-label={rotate ? 'Previous Upsell Product' : 'Next Upsell Product'} role={'button'}>
      <ArrowDown className={`fill-white ${rotate ? 'rotate-90' : 'rotate-[270deg] scale-x-[-1]'}`} />
		</div>
	)
}

export default function CartUpsell(props: any) {
  const lineItems = useLineItems()
  const queryData = useStaticQuery(graphql`
    query CartUpsellQuery {
      klaviyo: allSanityKlaviyo(limit: 1) {
        nodes {
          unreleasedProducts {
            shopifyId,
            variantTitle,
          }
          backInStockButtonText
          unreleasedProductButtonText
        }
      }
      products: allSanityShopifyProduct {
        nodes {
          title
          shortTitle
          summary
          slug {
            current
          }
          _id
          _type
          enabled
          shopifyId
          yotpoProductId
          visibilityRegions
          mainImage {
            _key
            _type
            asset: _rawAsset
          }
          productGroups {
            title
            _type
            slug {
              current
            }
          }
          priceFrom
          variants {
            variantTitle
            listingVariantTitle
            sku
            size
            color
            flavor
            configuration
            shopifyId
            shopifyIdBase64Encoded
            variantComponents {
              shopifyIdBase64Encoded
              price
              compareAtPrice
              sku
              variantTitle
              status {
                backOrderDetailsClassName
                backorder
                klaviyoTriggerId
                preorder
                watchlist
                watchlistFormTriggerClassName
                watchlistDescription:_rawWatchlistDescription
              }
              listingImage {
                crop: _rawCrop
                hotspot: _rawHotspot
                asset {
                  _id
                }
              }
            }
            listingImage {
              crop: _rawCrop
              hotspot: _rawHotspot
              asset {
                _id
                filename
                altText
              }
            }
            hide
          }
          youMightAlsoLike {
            _id
            _type
            title
            shopifyId
            yotpoProductId
            visibilityRegions
            summary
            slug {
              current
            }
            enabled
            productGroups {
              title
              _type
              slug {
                current
              }
            }
            productOptions: _rawProductOptions
            variants {
              variantTitle
              listingVariantTitle
              sku
              size
              color
              flavor
              configuration
              shopifyId
              shopifyIdBase64Encoded
              variantComponents {
                shopifyIdBase64Encoded
                price
                compareAtPrice
                sku
                variantTitle
                status {
                  backOrderDetailsClassName
                  backorder
                  klaviyoTriggerId
                  preorder
                  watchlist
                  watchlistFormTriggerClassName
                  watchlistDescription:_rawWatchlistDescription
                }
                listingImage {
                  crop: _rawCrop
                  hotspot: _rawHotspot
                  asset {
                    _id
                  }
                }
              }
              listingImage {
                crop: _rawCrop
                hotspot: _rawHotspot
                asset {
                  _id
                  filename
                  altText
                }
              }
              variantImages {
                ... on SanityImage {
                  asset {
                    _id
                    filename
                    altText
                  }
                }
              }
              hide
              visibilityRegions
            }
          }
        }
      }
      shopifyProducts: allShopifyProduct {
        nodes {
          shopifyId
          title
          options {
            shopifyId
            name
            values
          }
          variants {
            availableForSale
            shopifyId
            price
            presentmentPrices {
              compareAtPrice {
                amount
                currencyCode
              }
              price {
                amount
                currencyCode
              }
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  `)
  let products = queryData?.products?.nodes || []

  const [currentSlide, setCurrentSlide] = React.useState(0);

  // Filter by region visiblity
  products = products.filter((product) => isVisibleIn(product?.visibilityRegions));
  products.forEach((product) => {
    if (product?.youMightAlsoLike) {
      product.youMightAlsoLike = product?.youMightAlsoLike.filter((_product) => isVisibleIn(_product?.visibilityRegions) && _product?.enabled);
      product.youMightAlsoLike.forEach((upsellProduct) => {
        upsellProduct.variants = upsellProduct.variants.filter((_variant) => !_variant?.hide && isVisibleIn(_variant?.visibilityRegions))
      });
    }
  });

  const shopifyProducts = queryData?.shopifyProducts?.nodes || []
  const productIdsInCart: LineItemMap[] = useMemo(() => {
    return lineItems
      .filter((lineItem: any) => Boolean(lineItem?.merchandise))
      .map((lineItem: any) => {
        return {
          productId: lineItem?.merchandise.product.id,
          variantId: lineItem?.merchandise.id,
        }
      })
  }, [lineItems])

  products.map((product) => {
    product?.youMightAlsoLike?.map((upsell) => {filterUpsellsByAvailability(upsell, shopifyProducts)})
  });

  const crossProducts = useMemo(() => {
    return productIdsInCart
      .map(({ productId, variantId }) => {
        const matchingProduct = products.find(
          ({ yotpoProductId }: { yotpoProductId: string }) => variantIdFromShopifyId(productId) === yotpoProductId
        )

        if (!matchingProduct) {
          return null
        }

        // Get size of variant
        const matchingVariant = matchingProduct.variants.find(
          (variant: any) => variant.shopifyId === variantIdFromShopifyId(variantId)
        )

        if (!matchingVariant) {
          return null
        }

        const { size: matchingSize } = matchingVariant

        const existingProductIds = productIdsInCart.map(
          ({ productId: exProdId }) => variantIdFromShopifyId(exProdId)
        )

        let potentialUpsells = (matchingProduct?.youMightAlsoLike || [])
          .filter((entry: any) => !existingProductIds.includes(entry.yotpoProductId))


        // Skip if there is no potential candidates
        if (potentialUpsells.length === 0) {
          return null
        }

        // Probably should turn this into a size map
        const sizeUpsells = potentialUpsells
          .map((sizeProduct: any) => {
            let sizeVariantMatches = []

            // Will try to match by size if we have a size.
            if (matchingSize) {
              sizeVariantMatches = sizeProduct.variants.filter(
                (variant: any) => mapSizeValue(variant.size) === matchingSize
              )
            }

            // For products without it will fall back to the first variant
            if (sizeVariantMatches.length === 0) {
              // If there is still variants, include it even if we couldn't match a size
              const remainingVariants = getValue(sizeProduct, "variants")
              const sizedVariants = remainingVariants.filter(
                (variant: any) => !variant.size || mapSizeValue(variant.size) === "S"
              )

              if (sizedVariants) {
                sizeProduct.matchingVariants = sizedVariants

                return sizeProduct
              }

              return null
            }

            sizeProduct.matchingVariants = sizeVariantMatches

            return sizeProduct
          })
          .filter((match: any) => match !== null)

        return potentialUpsells
      })
      .filter((match: any) => match !== null)
  }, [productIdsInCart])

  const allUpsells = useMemo(() => {
    let allUpsells: any[] = []
    crossProducts.forEach((item) => {
      allUpsells = allUpsells.concat(item);
    })

    if (!allUpsells || allUpsells.length === 0) return []

    const removeDuplicates = (arr, field = "_id") => arr.filter(
      (a, i) => arr.findIndex((s) => a[field] === s[field]) === i
    )

    const removeNoMatchingVariants = (upsells) => upsells.filter(
      (upsell) => upsell.matchingVariants && upsell.matchingVariants.length !== 0
    )

    let uniqueUpsells: any[] = removeNoMatchingVariants(removeDuplicates(allUpsells));

    uniqueUpsells.forEach(upsell => {
      const { yotpoProductId, matchingVariants } = upsell
      const matchingShopifyProduct = shopifyProducts.find((product: any) => variantIdFromShopifyId(product.shopifyId) === yotpoProductId)
      upsell.klaviyo = queryData?.klaviyo?.nodes[0];

      if (matchingShopifyProduct) {
        matchingVariants.forEach((matchingVariant: any) => {
          const matchingShopifyProductVariant = matchingShopifyProduct.variants.find((shopifyVariant: any) => {
            return variantIdFromShopifyId(shopifyVariant.shopifyId) === matchingVariant.shopifyId
          })
          matchingVariant.price = matchingShopifyProductVariant
            ? getPriceFromVariantPresentmentPriceNodes(matchingShopifyProductVariant.presentmentPrices)
            : matchingVariant.price // Get correct price by region

        })
      }
    })

    return uniqueUpsells
  }, [crossProducts])

  if (!allUpsells || allUpsells.length === 0) return <></>

  let settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
		nextArrow: <SliderArrowComponent />,
		prevArrow: <SliderArrowComponent rotate={true} />,
    className: 'cart-upsell-slider'
  }

  return (
    <div className="relative">
      <Slider {...settings}>
        {allUpsells.map((product: any, index: any, key: any) => (
          <UpsellItem {...{ index, product, key, currentSlide }} />
        ))}
      </Slider>
    </div>
  )
}