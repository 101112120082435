import React, { useEffect, useState } from 'react'
import SearchItem from '../search-item';
import ColumnItem from '@components/rebrand/column-item';
import {ReactComponent as Spinner} from '@svgs/animated-spinner.svg'

const SearchResults = ({ searchItems, latestSearchItems, query, focused }) => {
  const [loading, isLoading] = useState(false)
  const { products, blogs, groups } = searchItems || [];
  const { products: defaultProducts, blogs: defaultBlogs, groups: defaultGroups } = latestSearchItems || [];

  useEffect(() => {
    isLoading(true)
    setTimeout(() => isLoading(false), 1000)
  }, [query])

  if (!focused) return (
    <SearchItem heading={'Trending Products'} query={query} defaultItems={defaultProducts} length={6} mbLength={4} cols={3} mbCols={1} className={'w-full'} card={true} />
  )

  return (
    <div className='flex max-md:flex-col gap-x-5 w-full overflow-hidden overflow-y-auto max-md:gap-y-5 h-full'>
      <ColumnItem className={'flex flex-col bg-light-gray w-full md:w-1/3 md:p-5 rounded-xl animate-slidein max-md:order-2'}>
        {loading 
          ? <Spinner className={'max-w-[100px] max-h-[100px]'} /> 
          : <>
            <SearchItem heading={'Blogs'} items={blogs} query={query} defaultItems={defaultBlogs} length={3} mbLength={3} cols={1} className={'max-md:px-2.5 max-md:pt-2.5'} />
            <SearchItem heading={'Categories'} items={groups} query={query} defaultItems={defaultGroups} length={3} mbLength={3} cols={1} className={'max-md:px-2.5 max-md:pb-2.5'} />
          </>
        }
      </ColumnItem>
      <ColumnItem className='w-full flex'>
        {loading 
          ? <Spinner className={'max-w-[100px] max-h-[100px]'} /> 
          : <SearchItem heading={'Products'} items={products} query={query} showMoreLink={true} defaultItems={defaultProducts} length={6} mbLength={3} cols={2} mbCols={1} card={true} />
        }
      </ColumnItem>
    </div>
  )
}

export default SearchResults