import getValue from "get-value"
import { getGatsbyImageData } from "gatsby-source-sanity"
import imageUrlBuilder from "@sanity/image-url"

export const placeholderImageId = "image-1144e50d6c122b795a0333b0172ed26cb2b46a0b-800x800-png"

export const getImageData = (imageAssetId, config) => {
  const sanityConfig = { projectId: 'yt8n50id', dataset: process.env.GATSBY_SANITY_DATASET }

  return getGatsbyImageData(imageAssetId, { ...config }, sanityConfig)
}

export const getImageUrlFromImage = (image, config) => {
  const sanityConfig = { projectId: 'yt8n50id', dataset: process.env.GATSBY_SANITY_DATASET }
  const imageData = buildImageObj(image)
  const possibleUrl = getValue(imageData, 'asset._ref')

  if (possibleUrl && possibleUrl.includes('https://')) {
      return possibleUrl
  }

  return imageUrlBuilder(sanityConfig)
    .image(buildImageObj(image))
    .width(1200)
    .height(630)
    .format('jpg')
    .auto('format')
    .url()
}

export const getImageUrl = (image, config) => {
  const sanityConfig = { projectId: 'yt8n50id', dataset: process.env.GATSBY_SANITY_DATASET}

  return imageUrlBuilder(sanityConfig)
    .image(buildImageObj(image))
    .url()
}

export const getAssetDataFromImage = image => {
  const ref = getValue(image, "asset._ref")
  const id = getValue(image, "asset._id", ref)

  return id
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: getValue(source, 'asset._ref') || getValue(source, 'asset._id') },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export const getSpeedsizeProps = ({width, height}) => {
  const speedSizeWidth = ',w_' + width;
  const speedSizeHeight = height && height !== null ? (',h_' + height) : '';

  return speedSizeWidth + speedSizeHeight;
}