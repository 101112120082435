import React, { useContext } from "react"
import StoreContext from "@context/store-context"
import { useSidebarContext } from "@context/sidebar-context"
import { useSearchContext } from "@context/search-context"

const useQuantity = () => {
  const {
    store: { cart },
  } = useContext(StoreContext)
  const items = cart ? cart.lines : []
  const total = items?.reduce((acc, item) => acc + item.quantity, 0)

  return total
}

const CartToggle = ({ className }) => {
  const quantity = useQuantity()
  const hasQuantity = quantity > 0;

  const { toggleDrawer } = useContext(StoreContext)
  const { toggleSidebar } = useSidebarContext();
  const { toggleSearch } = useSearchContext();

  const handleClick = e => {
    e.preventDefault()

    toggleDrawer()
    toggleSearch({override: false})
    toggleSidebar({override: false})
  }

  return (
    <button
      onClick={handleClick}
      className={`${className} flex items-center`}
    >
      <span>Cart</span>
      {hasQuantity && (
        <span className="relative ml-1 pt-[4px] border-1 border-solid border-black rounded-full text-sm leading-none w-5 h-5 flex justify-center items-center">
            {quantity}
            <span className="sr-only">items in cart</span>
        </span>
      )}
    </button>
  )
}

export default CartToggle
