import React from 'react';
import { getBgColorFromHex, getTextColorFromHex } from '@utils/general'
import { uid } from 'react-uid';

const Section = ({ containerClassname, innerClassname, title, settings, children }) => {
  const { id, classname, isContainer, hasColumns, colorSettings } = settings;

  const bgColor = !hasColumns ? getBgColorFromHex(colorSettings?.bgColor) : '';
  const textColor = !hasColumns ? getTextColorFromHex(colorSettings?.textColor) : '';

  return (
    <section className={`my-15 md:my-20 last:mb-0 first:mt-0 first:pt-small first:md:pt-5 ${classname || ''}`} id={id} key={`section-${uid({ id })}`} >
      <div className={`${isContainer ? 'container' : 'container-fluid'} ${containerClassname || ''}`}>
        {title && <h2 tabIndex={0} className='pl-0 md:pl-10'>{title}</h2>}
        <div className={`w-full flex flex-wrap md:flex-nowrap md:gap-5 rounded-xl ${innerClassname} ${bgColor} ${textColor}`}>
          {children}
        </div>
      </div>
    </section>
  );
};

Section.defaultProps = {
  innerClassname: '',
  settings: {
    id: '',
    classname: '',
    isContainer: true,
    colorSettings: {
      bgColor: {
        title: "Transparent",
        value: "transparent"
      },
      textColor: {
        title: "Black",
        value: "black"
      },
    },
  }
}

export default Section;
