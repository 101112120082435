import React, { useEffect, useState } from "react"
import { encode } from "shopify-gid"
import { useStylux } from "./provider"
import { ReactComponent as Close } from "@svgs/close.svg"
import { useProductQuickView } from "@src/context/product-quick-view-context"
import ScreenReaderText from "../screen-reader-text"

export function StyluxCta({
  variant,
  className = "",
  addToCartSelector,
  takeOverAtc = false,
  handleAddItemsToCart,
}) {
  const [currentOfferConfig, setCurrentOfferConfig] = useState(null)
  const [upsellActive, setUpsellActive] = useState(false)
  const [shouldAddToCart, setShouldAddToCart] = useState(false)

  const {
    state: { Stylux, setup },
    loadStyluxGlobalScriptsOnce,
  } = useStylux()

  const { addedCrossSellProducts, setAddedCrossSellProducts } =
    useProductQuickView()

  function getUpsellProductForCart({ product, offer, bundle, entries }) {
    if (!bundle) {
      return null
    }

    const productToUpsell = offer.upsellProduct
      ? offer.upsellProduct
      : offer.productReplacement ?? product

    const personalizationTextSummary = entries
      .map(entry => entry.simpleText?.personalizationText)
      .filter(Boolean)
      .reduce((acc, curr, i) => {
        acc[`Text ${i + 1}`] = curr
        return acc
      }, {})

    const merchandiseId = encode("ProductVariant", productToUpsell.productId)

    const attributes = {
      _list: "/collections/crates-and-accessories/",
      _STYLUX_BUNDLE: bundle.id,
      ...personalizationTextSummary,
    }

    if (offer.fulfillmentType === "FULFILLMENT") {
      attributes._STYLUX_FULFILLMENT_REPLACEMENT = "true"
    }

    return {
      merchandiseId,
      quantity: 1,
      attributes,
      shopifyId: productToUpsell.productId,
      sku: productToUpsell.sku,
      title: productToUpsell.title,
      shopifyIdBase64Encoded: merchandiseId,
      product: {},
      availableForSale: true,
      isAvailableInRegion: true,
    }
  }

  useEffect(() => {
    loadStyluxGlobalScriptsOnce()
  }, [])

  useEffect(() => {
    setUpsellActive(
      addedCrossSellProducts.some(upsellProduct => {
        return upsellProduct.product.attributes._STYLUX_BUNDLE
      })
    )
  }, [addedCrossSellProducts])

  useEffect(() => {
    removeProductFromUpsells()
  }, [variant])

  useEffect(() => {
    if (shouldAddToCart && typeof handleAddItemsToCart === "function") {
      handleAddItemsToCart()
    }

    setShouldAddToCart(false)
  }, [shouldAddToCart, handleAddItemsToCart])

  useEffect(() => {
    if (!Stylux || !setup) {
      return
    }

    const variantId = String(variant.shopifyId)

    setCurrentOfferConfig(null)

    Stylux.gql.queries
      .productWithOffers({ variables: { id: variantId } })
      .then(res => {
        const { product } = res

        // if there's no product or the current product does not match the
        // active/current variant (e.g., due to a race condition where the
        // user is quickly cycling through different variants)
        if (!product || product.productId !== String(variant.shopifyId)) {
          return
        }

        const offer = product.offers?.nodes?.[0]

        // there is an active offer; set the product/offer as part of the
        // current offer config
        if (offer) {
          setCurrentOfferConfig({ product, offer })
        } else {
          // there's no active offer
          setCurrentOfferConfig(null)
        }
      })
  }, [variant, setup, Stylux])

  useEffect(() => {
    if (!Stylux || !setup) {
      return
    }

    const { unsubscribe: closeModalUnsubscribe } = Stylux.pubsub.subscribe(
      "CLOSE_MODAL",
      async ({ openingSource, offer }) => {
        // prevent only adding the original/base product to cart
        // if the offer requires personalization
        if (offer.requiresPersonalization) {
          return null
        }

        // if the opening source for the modal is ADD_TO_CART, ensure
        // the base product is correctly added to cart when the modal
        // is closed
        if (openingSource === "ADD_TO_CART") {
          setShouldAddToCart(true)
        }

        return null
      }
    )

    const { unsubscribe: bundleCreateUnsubscribe } = Stylux.pubsub.subscribe(
      "BUNDLE_CREATE",
      async ({ product, offer, bundle, entries }) => {
        const upsellProductForCart = getUpsellProductForCart({
          product,
          offer,
          bundle,
          entries,
        })

        if (upsellProductForCart) {
          setAddedCrossSellProducts(prevState => [
            ...prevState,
            {
              product: Object.assign(upsellProductForCart, {
                price: getUpsellPrice(),
              }),
              quantity: 1
            },
          ])
        }

        Stylux.modals.close()
      }
    )

    function handleAddToCartClick(e) {
      // if there's no active offer or the target does not match the add
      // to cart selector, there's nothing to do so we can bail early
      if (
        !currentOfferConfig ||
        typeof addToCartSelector !== "string" ||
        !e.target.matches(addToCartSelector)
      ) {
        return
      }

      // only take over ATC if the offer requires personalization (i.e.,
      // on a personalization product directly, or `takeOverAtc` is
      // explictly enabled) and there's no active upsell. if the upsell
      // is already active, ATC behavior will be handled correctly as
      // part of the product details component

      if (
        (currentOfferConfig.offer.requiresPersonalization || takeOverAtc) &&
        !upsellActive
      ) {
        // prevent default add to cart behavior in the case there's an
        // active offer and the current click event matches the add to
        // cart selector
        e.preventDefault()
        e.stopImmediatePropagation()

        Stylux.modals.open({
          ...currentOfferConfig,
          openingSource: "ADD_TO_CART",
        })
      }
    }

    document.addEventListener("click", handleAddToCartClick, true)

    return () => {
      bundleCreateUnsubscribe()
      closeModalUnsubscribe()
      document.removeEventListener("click", handleAddToCartClick, true)
    }
  }, [
    Stylux,
    setup,
    currentOfferConfig,
    upsellActive,
    setAddedCrossSellProducts,
    handleAddItemsToCart,
  ])

  function openModal() {
    if (!currentOfferConfig) {
      return
    }

    Stylux.modals.open(currentOfferConfig)
  }

  function getUpsellPrice() {
    if (!currentOfferConfig) {
      return 0
    }

    if (currentOfferConfig.offer.fulfillmentType === "FULFILLMENT") {
      return (
        Number(currentOfferConfig.offer?.productReplacement?.price) -
        Number(currentOfferConfig.product?.price)
      )
    }

    return Number(
      currentOfferConfig?.offer?.productReplacement?.price ??
        currentOfferConfig?.offer?.upsellProduct?.price ??
        0
    )
  }

  function removeProductFromUpsells() {
    setAddedCrossSellProducts(prevState =>
      prevState.filter(
        crossSellProduct => !crossSellProduct.product.attributes._STYLUX_BUNDLE
      )
    )
  }

  if (!currentOfferConfig) {
    return <></>
  }

  return (
    <div
      className={className}
    >
      <div className={`cursor-pointer flex w-full py-2.5 group`}>
        {upsellActive ? (
          <span className="flex relative justify-between w-full border-l-4 border-solid border-safety-green pl-2.5">
            <ScreenReaderText
              srText={`Added ${
                currentOfferConfig.offer.ctaText
              } for $${getUpsellPrice()}`}
              className={"w-full"}
              ariaHidden={false}
            >
              <span className="font-medium text-safety-green">Added</span>{" "}
              {currentOfferConfig.offer.ctaText} |{" "}
              <span className="text-safety-green">${getUpsellPrice()}</span>
            </ScreenReaderText>
            <Close className={"w-4"} onClick={removeProductFromUpsells} />
          </span>
        ) : (
          <span className="flex relative justify-between w-full group-hover:pl-2.5 duration-300">
            <ScreenReaderText
              srText={`Add ${
                currentOfferConfig.offer.ctaText
              } for $${getUpsellPrice()}`}
              className={"w-full"}
              onClick={() => openModal()}
              ariaHidden={false}
            >
              <span className="font-medium">Add</span>{" "}
              {currentOfferConfig.offer.ctaText} |{" "}
              <span className="text-safety-green">${getUpsellPrice()}</span>
            </ScreenReaderText>

            <p className="py-1.5 px-2.5 z-[1] text-xs font-medium leading-3 rounded-md w-fit inline-flex items-center gap-1 bg-safety-green text-soft-black">
              New
            </p>
          </span>
        )}
      </div>
    </div>
  )
}
