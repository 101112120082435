/* analytics.js */
import Analytics from "analytics"
import klaviyo from "./klaviyo"
import gtagManagerPlugin from "@analytics/google-tag-manager"

let analytics = { track: (payload, details) => console.log(payload, details) }

const containerId = process.env.GTAG_MANAGER_ID
const env = process.env.NODE_ENV || process.env.GATSBY_NODE_ENV || "production"

// Set to global so analytics plugin will work with Gatsby
if (typeof window !== "undefined" && env !== "development") {
  let plugins = []

  if (containerId) {
    plugins = [
      klaviyo({ apiKey: 'KBXfpc' }),
      gtagManagerPlugin({
        containerId,
      })
    ]
  }

  analytics = Analytics({
    debug: true,
    plugins,
  })
  // window.Analytics = analytics
}

/* export for consumption in your components for .track & .identify calls */
export default analytics
