import React from "react"
import Headroom from "react-headroom"
import { HeaderData } from "./data"
import DesktopHeader from "./desktop"
import MobileHeader from "./mobile"
import AnnouncementBar from "./announcement-bar"
import Sidebar from "@components/sidebar"
import SidebarContent from "@components/sidebar/content"
import SocialMedia from "@components/sidebar/footerSocial"
import { SidebarProvider } from "@context/sidebar-context"
import SearchModal from "@components/search/search-modal"
import { SearchProvider } from "@context/search-context"
import useHeaderClass from "./helpers"

const appendProductsToGroup = (childs, products, hasProducts) => {
	if (!hasProducts) return childs;
  
	const mappedChilds = childs?.map(child => {
		const { internalLink: { _id, _type } } = child;
	
		if (_type === 'productGroup') {
			child.products = products?.filter(product => product?.productGroups?.find(group => group._id === _id))?.slice(0, 3);
		} else if (_id === "productsIndex") {
			child.products = products?.slice(0, 3);
		}
	
		return child;
	});
  
	return mappedChilds;
}

const Header = ({ headerData, footerData  }) => {
	let { header, products, sidebar, siteSettings: settings, blogPosts, resourcesPosts, groups, search } = headerData;

	header = header?.map(node => ({
		...node,
		childNodes: appendProductsToGroup(node.childNodes, products, node.hasProducts),
	}));

	const { onPin, onUnfix, onUnpin } = useHeaderClass();

	return (
		<header>
			<SidebarProvider>
				<AnnouncementBar data={settings[0].announcementBar} />
				<SearchProvider>
					<Headroom onPin={onPin} onUnpin={onUnfix} onUnfix={onUnpin}>
						<DesktopHeader data={header} settings={settings} />
						<MobileHeader settings={settings} />
						
						<SearchModal {...{products, blogPosts, resourcesPosts, groups, search}} />
						
						<Sidebar>
							<SidebarContent nodes={[...sidebar, ...header]} className={'main-sidebar'} />
							<SocialMedia footerData={footerData} />
						</Sidebar>
					</Headroom>
				</SearchProvider>
			</SidebarProvider>
		</header>
	)
}

export default Header
