import { useStaticQuery, graphql } from "gatsby";
import getValue from "get-value";

export const useGroupsQuery = () => {
  const queryData = useStaticQuery(graphql`
    query Groups {
      categories: allSanityCategory {
        nodes {
          _id
          slug: _rawSlug
        }
      }
      productGroups: allSanityProductGroup(sort: { fields: orderRank }) {
        nodes {
          _id
          slug: _rawSlug
        }
      }
    }
  `);

  const sanityCategories = getValue(queryData, "categories.nodes", []);
  const sanityProductGroups = getValue(queryData, "productGroups.nodes", []);

  return { sanityCategories, sanityProductGroups };
};
