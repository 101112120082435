import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const Heading = ({ children, priority, level, tag, color, className }) => {
  const defaultClasses = ""
  const headingClassMap = new Map([
    [
      1,
      "",
    ],
    [
      2,
      "",
    ],
    [
      3,
      "",
    ],
  ])

  const classes = headingClassMap.get(parseInt(level))
  const cx = `${classes} ${className}`

  if (!priority) {
    priority = level
  }

  const H = `h` + priority

  if (tag) {
    const Custom = tag
    return <Custom className={cx}>{children}</Custom>
  }

  return (
    <H className={cx} tabIndex={0}>
      {children}
    </H>
  )
}

export default Heading

Heading.defaultProps = {
  className: '',
  priority: 2,
  level: 2,
}
