import React, { useState, useEffect, useRef, useMemo } from "react"
import * as bodyScrollLock from "body-scroll-lock"
import StoreContext from "@context/store-context"
import LineItem from "../line-item"
import useJustUnoBannerState from "../useJustUnoBannerState"
// import ShippingProgressBar from "../shipping-progress-bar"
import { useSpring, animated } from "react-spring"
import RichText from "@components/rich-text"
import PromoCodeInput from "@components/cart/promo-code-input"
import CartUpsell from "@src/components/cart/upsell"
import AffirmPromotion from "../../widgets/affirm-promotion"
import { getRegionHandle } from "@utils/region"
import { getCookie } from '@analytics/cookie-utils'
import { decode } from "shopify-gid"
import ProductQuickView from "@components/product/quick-view"
import { ProductQuickViewProvider } from "@context/product-quick-view-context"
import Section from "@components/section"
import { ReactComponent as Close } from "@svgs/close.svg";
import Button from "../../button"
import { AddToCartProvider } from "@src/context/add-to-cart-context"
import { getDiscountAmount, getDiscountDisplayedAmount, getDiscountLabel, getDiscounts, getPrice } from "../helpers"
import { trackViewCart } from "@src/services/analytics"
import { captureOctaneAddToCart, captureOctaneViewCheckout, checkForCashbackProducts, captureOctaneViewCart } from "./helpers"
import CashbackModal from "@src/components/cashback-modal"

const CartDrawer = () => {
  const cartDrawerRef = useRef()
  const [error, setError] = useState("")
  const [cashbackOpened, setCashbackOpened] = useState(false);

  const {
    addLineItemsToCart,
    addDiscount,
    removeDiscount,
    toggleDrawer,
    store: { cart, isDrawerOpen },
  } = React.useContext(StoreContext)

  const { discountAllocations = [] } = cart
  const { hasTopBanner } = useJustUnoBannerState(isDrawerOpen)

  useEffect(() => {
    if (cartDrawerRef.current) {
      if (isDrawerOpen) {
        bodyScrollLock.disableBodyScroll(cartDrawerRef.current, { allowTouchMove: () => true })
        cartDrawerRef.current.focus();
      } else {
        bodyScrollLock.enableBodyScroll(cartDrawerRef.current)
      }
    }
  }, [isDrawerOpen, cart])

  useEffect(() => {
    if (isDrawerOpen) {
      trackViewCart(cart, cart.lines);
    }
  }, [isDrawerOpen])

  useEffect(() => {
    if (window.location.href.toString().includes("/#view-cart")) {
      toggleDrawer();
    }
  }, [])

  useEffect(() => {
    captureOctaneAddToCart(addLineItemsToCart, toggleDrawer);
    captureOctaneViewCart(toggleDrawer);
    captureOctaneViewCheckout(getCheckoutURL);
  }, [cart?.id])

  const scriptDiscountValue = getPrice(
    (discountAllocations || [])
      .filter(({ __typename }) => __typename === "ScriptDiscountApplication")
      .map(({ value }) => parseFloat(value?.amount || 0))
      .reduce((curr, acc) => curr + acc, 0)
  )

  const discounts = getDiscounts(cart);

  const discountLabel = getDiscountLabel(discounts);
  const discountAmount = getDiscountAmount(discounts);
  const discountDisplayedAmount = getDiscountDisplayedAmount(discounts);

  const priceBeforeTaxes = () => {
    let price = cart.cost?.totalAmount ? cart.cost?.totalAmount.amount : cart.cost?.totalAmount;

    if (price && cart.cost?.totalTaxAmount?.amount) {
      price -= cart.cost?.totalTaxAmount?.amount;
    }

    return getPrice(price);
  }

  useEffect(() => {
    if ("URLSearchParams" in window) {
      const couponCode = new URLSearchParams(location.search).get("coupon")

      if (cart && cart.id) {
        if (couponCode) {
          if (discounts?.length > 0) {
            const { code } = discounts[0]

            if (code && code !== couponCode.toUpperCase()) {
              setTimeout(() => {
                addDiscount(couponCode)
              }, 1000)
            }
          } else if (cart?.discountCodes?.length < 1) {
            setTimeout(() => {
              addDiscount(couponCode)
            }, 1000)
          }
        }
        
        checkForCashbackProducts(cart, addLineItemsToCart);
      }
    }
  }, [cart])

  const getCheckoutURL = () => {
    const CJ_REDIRECT_ENDPOINT = "https://www.diggs.pet/api/cj/checkout"
    const region = getRegionHandle();
    const cjEvent = getCookie("cje");
    if (region === 'canada' && cjEvent) {
      window.location.href = `${CJ_REDIRECT_ENDPOINT}?url=${cart.checkoutUrl}&cjEvent=${cjEvent}`
    }

    window.location.href = cart.checkoutUrl + "&cid=" + cart.id;
  }

  const isOpen = isDrawerOpen
  const isOpenClassName = ""
  const isCloseClassName = "pointer-events-none"

  let wrapperClassName = `fixed w-full h-full flex flex-col top-0 left-0 overflow-hidden text-white z-[52] cart-drawer-wrapper`
  let drawerClassName = `cart-drawer`

  if (hasTopBanner) {
    drawerClassName += ` pt-18 md:pt-18 lg:pt-18`
  }

  if (isOpen) {
    wrapperClassName = `${wrapperClassName} ${isOpenClassName}`
    drawerClassName = `${drawerClassName}`
  }

  if (!isOpen) {
    wrapperClassName = `${wrapperClassName} ${isCloseClassName}`
    drawerClassName = `${drawerClassName}`
  }

  const lineItems = useMemo(() => {
    if (cart.lines.length === 0) {
      return <RichText className="text-base text-center" paragraphClassName="grow-1" childrenHTML="No items in your cart."></RichText>
    }

    return cart.lines
      .filter(
        lineItem =>
          !lineItem.attributes.find(
            attr => attr.key === "_warranty_offer_id"
          )
      )
      .map((lineItem, index) => {
        const warrantyDetails = cart.lines.find(item =>
          item.attributes.find(
            attr =>
              attr.key === "_variantId" &&
              attr.value === decode(lineItem.variant.id).id
          )
        )

        lineItem.warrantyDetails = warrantyDetails

        return (
          <LineItem
            key={lineItem.id.toString()}
            lineItem={lineItem}
            index={index}
            setCashbackOpened={setCashbackOpened}
          />
        )
      })
  }, [cart])

  const handleAddDiscountCode = async discountCode => {
    setError("")

    if (!discountCode || discountCode.length === 0) {
      removeDiscount()
    } else {
      const resp = await addDiscount(discountCode)
      const caughtError = resp === null
      const discountCodeNotApplicable =
        resp && resp.cart.discountCodes.find(code => code.code === discountCode)?.applicable
      const discountErrors =
        resp && resp.userErrors

      if (caughtError) {
        setError("Something went wrong. Please try again.")
      } else if ((discountErrors && discountErrors.length !== 0) || !discountCodeNotApplicable) {
        setError("Please enter a valid discount code")
      }
    }
  }

  const bgAnimateProps = useSpring({
    opacity: isOpen ? 0.25 : 0,
    from: { opacity: 0 },
  })
  const drawerAnimateProps = useSpring({
    transform: `translateX(${isOpen ? 0 : 100}%)`,
    opacity: isOpen ? 1 : 0,
    visibility: isOpen ? 'visible' : 'hidden',
    from: { opacity: 0, visibility: 'hidden' }
  })

  const handleCloseClick = e => {
    e.preventDefault()

    toggleDrawer()
  }

  return (
  <AddToCartProvider>
      <ProductQuickViewProvider>
        <animated.div className={wrapperClassName} tabIndex="-1" ref={cartDrawerRef}>
          <animated.div
            className="absolute w-full h-full bg-black opacity-25 modal-overlay z-20"
            style={bgAnimateProps}
            onClick={() => toggleDrawer()}
          ></animated.div>
          <Section innerClassname="absolute right-0 h-full" settings={{ classname: 'h-full !mt-0', isContainer: true }} containerClassname="h-full !mt-0">
            <animated.div className={drawerClassName} style={drawerAnimateProps} tabIndex={0} aria-hidden="false" aria-label="Cart drawer opened">
              <div className="flex flex-col h-full">
                <header className="flex flex-col max-md:gap-y-4 bg-light-gray p-5 rounded-xl">
                  <div className="flex items-center w-full gap-x-2.5">
                    <div className="w-full flex justify-between items-center">
                      <span className="text-xl font-medium">Shopping Cart</span>
                      <button 
                        type="button" 
                        aria-label="Close cart drawer" 
                        onClick={handleCloseClick}
                        className="p-4 duration-300 rounded-full cursor-pointer absolute right-7 top-7"
                      >
                        <Close className="w-4" focusable="false" />
                      </button>
                    </div>
                  </div>
                  {/* <ShippingProgressBar
                    show={qualifiesForFreeShipping}
                    priceValue={untilFreeShipping}
                    hasFreeShippingCode={hasFreeShippingCode}
                    overrideText={
                      hasFreeGift()
                        ? "Congrats! Your free gift has been added. 🎁"
                        : null
                    }
                    price={formatPrice({
                      price: untilFreeShipping,
                      minimumFractionDigits: 2,
                    })}
                    width={`${(checkoutSubtotal / shippingThreshold) * 100}%`}
                    link={URLS.PRODUCTS}
                    linkText="Shop Add-Ons"
                  /> */}
                </header>

                <div
                  className={`col-span-6 gap-y-5 pt-5 flex flex-col overflow-x-hidden overflow-y-auto`}
                >
                  <div className="flex flex-col gap-y-2.5" aria-label={'Line items'} tabIndex={0} role={'list'}>
                    {lineItems}
                  </div>
                  <CartUpsell />
                </div>

                <footer className="flex flex-col gap-y-5 pt-2.5 mt-auto">
                  <PromoCodeInput onSubmit={handleAddDiscountCode} error={error} />

                  <div className="flex flex-col gap-y-2.5" tabIndex={0}>
                    {discounts?.length > 0 && (
                      <p className="flex justify-between" aria-label={`-${discountAmount === 0 ? scriptDiscountValue : discountDisplayedAmount} off with code ${(discountLabel && discountLabel !== '') && `(${discountLabel})`}.`}>
                        <span className="antialiased text-soft-black text-base font-medium">
                          Discount {(discountLabel && discountLabel !== '') && `(${discountLabel})`}
                        </span>
                        <span className="text-safety-green antialiased text-base font-medium">
                          -
                          {discountAmount === 0
                            ? scriptDiscountValue
                            : discountDisplayedAmount}
                        </span>
                      </p>
                    )}

                    <p className="flex justify-between" aria-label={`Cart Subtotal: ${priceBeforeTaxes()} dollars`}>
                      <span className="antialiased text-soft-black text-base font-medium">
                        Subtotal
                      </span>
                      <span className="text-safety-green antialiased text-base font-medium">
                        {priceBeforeTaxes()}
                      </span>
                    </p>
                  </div>

                  <div className="flex flex-col gap-y-2.5">
                    <Button
                      className={cart.lines.length === 0
                        ? "bg-opacity-75"
                        : ""
                      }
                      theme={'btn-secondary'}
                      onClick={getCheckoutURL}
                      disabled={
                        cart.lines.length === 0
                      }
                    >
                      Checkout
                    </Button>

                    <AffirmPromotion
                      total={cart.cost?.totalAmount}
                      center={true}
                      type="cart"
                    />
                  </div>
                </footer>
              </div>
            </animated.div>
          </Section>
        </animated.div>
        <CashbackModal opened={cashbackOpened} setOpened={setCashbackOpened} />
        <ProductQuickView />
      </ProductQuickViewProvider>
    </AddToCartProvider>
  )
}

CartDrawer.defaultProps = {
  items: [],
  isOpen: true,
}

export default CartDrawer
