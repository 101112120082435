import React from 'react'
import { ReactComponent as ArrowDown } from "@svgs/arrow-down.svg";
import { wrapperClasses, contentClasses, titleClasses } from './helpers'
import { onEnterKeyPress } from '@src/utils/general';

const SidebarAccordion = ({
	heading,
	opened,
	style,
	isAccordion,
	onAccordionClick,
	hasIcon,
	children
}) => {
	const { wrapper, title, content } = style
	const { desktop, mobile } = isAccordion

	const accordionClassName = `${desktop ? "desktop-" : ""}${mobile ? "mobile-" : ""}${opened ? "opened" : "closed"}`;

	return (
		<nav className={`flex flex-col transition-all duration-500 px-10 py-5 ${wrapperClasses[accordionClassName]} ${wrapper ? wrapper : ''}`}>
			<div className={`flex justify-between items-center sidebar-heading transition-all duration-300 pb-5 ${titleClasses[accordionClassName]} ${title ? title : ''}`} onClick={onAccordionClick} tabIndex={0} aria-label={`Sidebar Accordion: ${heading}`} onKeyDown={(e) => onEnterKeyPress(e, onAccordionClick)}>
				{heading}
				{hasIcon && <ArrowDown className={`fill-darker-gray ${!desktop ? 'md:hidden' : ''}`} />}
			</div>
			<ul className={`flex flex-col gap-y-5 overflow-hidden transition-all duration-300 ${contentClasses[accordionClassName]} ${content ? content : ''}`} >{children}</ul>
		</nav>
	)
}

SidebarAccordion.defaultProps = {
	opened: false,
	style: {
		wrapper: '',
		title: '',
		content: '',
	},
	isAccordion: {
		desktop: false,
		mobile: true,
	},
	hasIcon: true,
}

export default SidebarAccordion