import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import Backdrop from '@components/sidebar/backdrop';
import { getSearchCookie, setSearchCookie } from './helpers'
import { toggleBodyScrollLock } from '@utils/general';

const SearchContext = createContext();

export const useSearchContext = () => useContext(SearchContext);

export const SearchProvider = ({ hasBackdrop = true, children }) => {

  const [searchState, setSearchState] = useState({
    query: '',
    isOpen: false,
    isFocused: false,
    defaultItems: {
      products: [],
      blogs: [],
      groups: []
    },
    items: {
      products: [],
      blogs: [],
      groups: []
    },
    latestQueries: [],
    latestItems: [],
    closestWordToQuery: '',
  })

  const toggleSearch = useCallback((override) => {
    setSearchState(prevState => {
      const newIsOpen = override ? override.override : !prevState.isOpen;
      if (newIsOpen !== prevState.isOpen) {
        return {
          ...prevState,
          isOpen: newIsOpen,
          query: '',
          isFocused: false,
        };
      }
      return prevState;
    });
  }, []);

  const onSubmit = useCallback(({ search }) => {
    setSearchCookie(search);
    setSearchState(prevState => {
      const latestQueries = getSearchCookie();
      if (search !== prevState.query || latestQueries !== prevState.latestQueries) {
        return {
          ...prevState,
          query: search,
          latestQueries: latestQueries
        };
      }
      return prevState;
    });
  }, []);

  const clearSearch = useCallback((setValue) => {
    setValue('search', '');
    setSearchState(prevState => {
      if (prevState.query !== '') {
        return {
          ...prevState,
          query: ''
        };
      }
      return prevState;
    });
  }, []);

  const onInputFocus = useCallback(() => {
    setSearchState(prevState => {
      if (!prevState.isFocused) {
        return {
          ...prevState,
          isFocused: true,
        };
      }
      return prevState;
    });
  }, []);

  // useEffect(() => {
  //   setSearchState(prevState => {
  //     const { items: newItems, closestWordToQuery } = getItems(prevState.query, searchState.items);
  //     const newLatestItems = getLatestItems(prevState.latestQueries, searchState.defaultItems);
  //     if (JSON.stringify(newItems) !== JSON.stringify(prevState.items) || 
  //         JSON.stringify(newLatestItems) !== JSON.stringify(prevState.latestItems)) {
  //       return {
  //         ...prevState,
  //         items: newItems,
  //         latestItems: newLatestItems,
  //         closestWordToQuery,
  //       };
  //     }
  //     return prevState;
  //   });
  // }, [searchState.query, searchState.latestQueries, searchState.defaultItems, searchState.items]);

  useEffect(() => {
    toggleBodyScrollLock('body', searchState.isOpen)
  }, [searchState.isOpen]);

  return (
    <SearchContext.Provider value={{ toggleSearch, searchState, setSearchState, onSubmit, clearSearch, onInputFocus }}>
      {children}
      {hasBackdrop && <Backdrop opened={searchState.isOpen} onClick={() => toggleSearch({override: false})} />}
    </SearchContext.Provider>
  );
};
