import React from "react"
import { Buffer } from "buffer"
import getValue from "get-value"
import { isVisibleIn } from "@utils/region"
import { isEqual, omit } from "lodash"
import { currencyOptionCompareAtPrice, currencyOptionPrice } from "./products/pricing"

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

function truncateString(str, num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "..."
}

function htmlifyText(text) {
  return <span dangerouslySetInnerHTML={{ __html: text }}></span>
}

function variantIdFromShopifyId(id) {
  if(id) {
    const parts = id.split('/');
    return parts[parts.length - 1];
  }
}

function shopifyIdToBase64(id) {
  const base64 = Buffer.from(id).toString('base64');

  return base64
}

function ascSizeSorter(valA, valB) {
  if (!valA || !valB) return 0
  const sortMap = ['XS', 'S', 'Small', 'M', 'Medium', 'I', 'L', 'Large', 'S / M', 'I / L', 'XL', 'Extra Large']
  const indexA = sortMap.indexOf(valA)
  const indexB = sortMap.indexOf(valB)
  return indexA - indexB;
}

// TODO: Remove after images migration
function overrideOptions(opt) {
  const overrideValues = {
    "Thats My Jam": "That's My Jam",
    "Gold N Delicious": "Gold N' Delicious",
    "Small": "S",
    "Medium": "M",
    "Large": "L",
  }

  return overrideValues[opt] ? overrideValues[opt] : opt
}

function getOptionTitle(variant) {
  const { selectedOptions } = variant
  const sizeMap = {
    XS: "Extra Small",
    S: "Small",
    M: "Medium",
    I: "Intermediate",
    L: "Large",
    XL: "Extra Large",
  }
  const selectedOption = selectedOptions?.find(option => option.name === "Size")
  const optionValue = getValue(selectedOption, "value")

  return getValue(sizeMap, `${optionValue}`)
}

function getInitialVariant(variants, searchParams = null) {
  let variant;
  const localWindow = typeof window !== "undefined" && window
  const params = new URLSearchParams(searchParams || localWindow?.location?.search)
  const variantId = params.get("variantId") || params.get('variant')
  if (variantId) {
    variant = variants
    .find(_variant => 
      _variant.shopifyId === variantId && 
      isVisibleIn(_variant.visibilityRegions) === true && !_variant.hide)
  } else {
    variant = variants
      ?.find(_variant => _variant.isDefaultVariant && isVisibleIn(_variant.visibilityRegions) === true) || variants
      ?.find(_variant => 
        isVisibleIn(_variant.visibilityRegions) === true && !_variant.hide && _variant.availableForSale)
      || variants[0]
  }
  return variant
}

function extractSizeValue(variant) {
    const sizeOption = variant?.selectedOptions?.find(opt => opt.name === 'Size');
    return sizeOption ? getValue(sizeOption, 'value') : null;
};

function findInitialVariant(variants, isCrossSell) {
  // console.log(variants)
  const byOptionsAndPrice = (varA, varB) => {
    const sizeAOpt = extractSizeValue(varA);
    const sizeBOpt = extractSizeValue(varB);
    const sizeSortValue = (!sizeAOpt || !sizeBOpt) ? 0 : sizeBOpt.localeCompare(sizeAOpt);

    const priceAOpt = getValue(varA, 'price');
    const priceBOpt = getValue(varB, 'price');
    const priceSortValue = (!priceAOpt || !priceBOpt) ? 0 : priceAOpt - priceBOpt

    return sizeSortValue + priceSortValue;
  }

  let variant;
  const localWindow = typeof window !== "undefined" && window
  const params = new URLSearchParams(localWindow?.location?.search)
  const variantId = params.get("variantId") || params.get("variant")
  
  if (!isCrossSell && variantId) {
    variant = variants
      .find(_variant => 
        _variant?.shopifyId === variantId && 
        isVisibleIn(_variant.visibilityRegions) === true && !_variant.hide ) || variants[0]
  } else {
    variant = variants
      ?.filter(_variant => _variant.hide !== true && _variant.availableForSale && isVisibleIn(_variant.visibilityRegions) === true)
      ?.sort(byOptionsAndPrice)
      ?.find(_variant => _variant.isDefaultVariant && isVisibleIn(_variant.visibilityRegions) === true) || variants[0]
  }
  return variant
}

function filteredAndSortedOptions(_options, variants) {
  if (!_options) return _options;
  const options = JSON.parse(JSON.stringify(_options));

  options?.forEach(option => {
    const _values = []

    option.values = option.values.filter(value => {
      variants
        ?.filter(variant => !variant?.hide)
        ?.forEach(_variant => {
          return _variant?.selectedOptions?.forEach(_selectedOption => _values.push(_selectedOption.value))
        })

      return _values.indexOf(value) >= 0
    })
  })

  return (
    options?.map(({ shopifyId, name, values }, originalIndex) => {
      return { shopifyId, name, values, originalIndex }
    })
      .sort(({ name }, { name: nameB }) => name.localeCompare(nameB))
  )
}

function filterVariantsByRegion(variants, productEntry) {
  variants?.forEach(variant => {
    const productVariant = productEntry.variants.find((_var) =>
      _var.shopifyId == variantIdFromShopifyId(variant.shopifyId) || _var?.shopifyId == variant?.shopifyIdBase64Encoded
    );

    // Assign all Shopify info to Sanity productEntry, don't overwrite existing info
    productVariant && Object.entries(variant).forEach(([key, value]) =>
      !productVariant[key] ? productVariant[key] = value : ''
    )
    variant.isAvailableInRegion = (!productVariant || isVisibleIn(productVariant.visibilityRegions))
  })

  return variants
}

function filterUpsellsByAvailability(product, shopifyProducts) {
  const matchedShopifyProduct = shopifyProducts.find((_prod) => 
    variantIdFromShopifyId(_prod.shopifyId) == product.yotpoProductId
  );

  product?.variants?.forEach(variant => {

    const productVariant = matchedShopifyProduct?.variants.find((_var) => 
        variantIdFromShopifyId(_var.shopifyId) == variant.shopifyId
    );


    // Assign all Shopify info to Sanity productEntry, don't overwrite existing info
    variant && Object.entries(productVariant).forEach(([key, value]) => 
      !variant[key] ? variant[key] = value : ''
    )

    variant.isAvailableInRegion = (!productVariant || isVisibleIn(productVariant.visibilityRegions))
  })

  product.options = matchedShopifyProduct?.options;

  return product
}

function getSelectedDisplayValue(name, selectedValueValue, productOptions) {
  const currentOptionName = productOptions
    ?.find(({optionName}) => optionName?.toLowerCase() === name.toLowerCase())?.optionValues
    .find(({originalValueName}) => originalValueName?.toLowerCase() === selectedValueValue?.toLowerCase())
  return currentOptionName?.customDisplayedValueName
}

const setQueryStringParameter = (name, value) => {
  const localWindow = typeof window !== "undefined" && window

  if (!localWindow) return

  const params = new URLSearchParams(localWindow?.location?.search)

  params.set(name, value)
  localWindow?.history.replaceState(
    {},
    "",
    decodeURIComponent(`${window.location.pathname}?${params}`)
  )
}

function findMatch(name, value, variants, currentVariant) {
  const otherOptions = currentVariant?.selectedOptions?.filter(k => k.name !== name)

  const allMatch = variants
    .filter(variant => // Get all variants with same Option Value
      variant.selectedOptions?.find(option => 
        option.name === name && option.value === value
      )
    )

  const match = allMatch.find(({ selectedOptions }) => {
    const optionsToCompare = selectedOptions
      .filter(option => option.name !== name)
      .map(option => omit(option, ['_key']))
    const _otherOptions = otherOptions?.map(option => omit(option, ['_key']))

    return isEqual(_otherOptions, optionsToCompare)
  })

  return match
}

const checkStatus = (name, value, variants, currentVariant) => {
  const match = findMatch(name, value, variants, currentVariant)

  let isDisabled, isUnavailable, isOnSale, isAvailableWithPrime
  isDisabled = isUnavailable = isOnSale = isAvailableWithPrime = false

  if (match === undefined) {
    return ({
      isDisabled: true,
      isUnavailable: true,
      isOnSale,
      isAvailableWithPrime,
    })
  }
  const price = parseFloat(currencyOptionPrice(match))
  const compareAtPrice = parseFloat(currencyOptionCompareAtPrice(match))

  if (name === "Title" || match.hide) { isDisabled = true }
  if (name !== "Title" && !match?.availableForSale || (!match?.isAvailableInRegion && !isVisibleIn(match?.visibilityRegions))) { isUnavailable = true }
  if (name !== "Title" && compareAtPrice && compareAtPrice !== price) { isOnSale = true }
  if (match?.isAvailableWithPrime) { isAvailableWithPrime = match?.isAvailableWithPrime}

  return ({ isDisabled, isUnavailable, isOnSale, isAvailableWithPrime })
};

function checkOptionChecked(checkName, checkValue, currentVariant) {
  const matchingOption = currentVariant?.selectedOptions?.find(
    ({ name, value }) => name === checkName && value === checkValue
  )

  return matchingOption !== undefined
}

function useIsSsr() {
  const [isSsr, setIsSsr] = React.useState(true);

  React.useEffect(() => {
    setIsSsr(false);
  }, []);

  return isSsr;
}

function trackTripleWhaleAddToCart(ids) {
  if (typeof window.TriplePixel !== 'undefined') {
    if (typeof ids === 'object') {
      ids.forEach(id => {
        window.TriplePixel('AddToCart', {item: `${id}`, q: 1});
      })
    } else {
      window.TriplePixel('AddToCart', {item: `${ids}`, q: 1});
    }
  }
}

export {
  slugify, truncateString, htmlifyText, variantIdFromShopifyId, 
  ascSizeSorter, getInitialVariant, findInitialVariant, overrideOptions, 
  getOptionTitle, filteredAndSortedOptions,filterVariantsByRegion, 
  getSelectedDisplayValue, setQueryStringParameter, checkOptionChecked, 
  findMatch, checkStatus, filterUpsellsByAvailability, useIsSsr, 
  shopifyIdToBase64, trackTripleWhaleAddToCart,
}
