import React from 'react';
import { checkOptionChecked, checkStatus, getSelectedDisplayValue } from "@utils/product";
import ScreenReaderText from "@components/screen-reader-text";
import { onEnterKeyPress } from '@src/utils/general';

const OptionDropdown = ({ variants, currentVariant, option, productOptions, handleOptionChange, className, optionLabels }) => {
  const { name, values, originalIndex } = option
 
  const renderDropdown = () => (
    <React.Fragment key={originalIndex}>
      {values?.map((value) => {
        const { isDisabled, isUnavailable, isOnSale, isAvailableWithPrime } = checkStatus(name, value, variants, currentVariant);
        const isSelected = checkOptionChecked(name, value, currentVariant);
        const selectedDisplayValue = getSelectedDisplayValue(name, value, productOptions)

        return (
          <div
            key={originalIndex + value}
            data-option-type={`option-${name.toLowerCase()}`}
            className={`w-full cursor-pointer ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={() => {
              if (!isDisabled) {
                handleOptionChange(originalIndex, { target: { value } });
              }
            }}
            onKeyDown={(e) => onEnterKeyPress(e, () => {
              if (!isDisabled) {
                handleOptionChange(originalIndex, { target: { value } });
              }
            })}
            tabIndex={0}
          >
            <div className={`py-2.5 px-6 border-t border-neutral-200 w-full flex justify-start items-center gap-2 ${isSelected ? "bg-light-gray" : ""} `}>
              <div className="text-base flex gap-3">
                <ScreenReaderText srText={`${isOnSale ? 'On Sale: ' : ''} ${isUnavailable ? 'Out of Stock: ' : ''}${isAvailableWithPrime && isUnavailable ?  "Available with Prime Only" : ""} ${name} ${value} ${isSelected ? ', selected' : '' }`} />
                <div className="font-medium min-w-6" aria-hidden={true}>{value}</div>
                {selectedDisplayValue !== value &&
                  <div className="text-black text-base font-light" aria-hidden={true}>{selectedDisplayValue}</div>}
              </div>
              {optionLabels(isOnSale, isUnavailable, isAvailableWithPrime)}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );

  return <div className={`w-full pt-5 ${className}`}>{renderDropdown()}</div>;
};

OptionDropdown.defaultProps = {
  className: '',
}

export default OptionDropdown;
