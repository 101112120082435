import React, { useEffect, useRef } from 'react'
import { ReactComponent as Close } from "@svgs/close.svg";
import SearchToggle from '@components/search/search-toggle';
import { useSidebarContext } from '@context/sidebar-context';
import useHeaderClass from '../header/helpers';

const Sidebar = ({ showSearchBar, showCloseButton, className, children }) => {
	const sidebar = useRef();
	const wrapper = useRef();
	const { sidebarOpened, toggleSidebar } = useSidebarContext();
	const { onSidebarOpened, onSidebarClosed } = useHeaderClass();

	useEffect(() => {
		if (sidebarOpened) {
			sidebar.current.focus();
			onSidebarOpened();
		} else {
			onSidebarClosed();
		}
	}, [sidebarOpened])

	return (
		<div ref={sidebar} className={`${sidebarOpened ? 'opened' : 'transition-all opacity-0'} sidebar fixed w-full max-xs:top-40 top-[145px] md:top-[132px] mb-5 flex flex-col ${className || ''}`} tabIndex={-1} aria-hidden={!sidebarOpened}>
			{showSearchBar &&
			<div className={`md:hidden flex ${sidebarOpened ? 'left-0' : '-left-full delay-300'} relative transition-all duration-200 bg-white mx-large md:mb-5 mb-small rounded-xl sidebar-heading font-normal`} tabIndex={-1}>
					<SearchToggle className="px-5 py-4" />
				</div>
			}
			<div ref={wrapper} className={`overflow-hidden h-full container`} onClick={(e) => wrapper.current == e.target && toggleSidebar()} tabIndex={sidebarOpened ? 0 : -1}>
				<div className={`${sidebarOpened
					? 'max-md:left-0 md:right-0 md:ml-auto delay-300'
					: 'md:-right-full max-md:-left-full md:ml-auto'
					} relative  transition-all duration-300 bg-white flex flex-col rounded-xl mt-0 md:min-w-sidebar md:max-w-fit md:mx-10 lg:mr-5 mx-2.5 h-full justify-between`
				}>
					{showCloseButton &&
						<button className="btn-close absolute p-2 right-3 top-4 md:right-8 md:top-8 z-10" onClick={toggleSidebar} aria-label="Sidebar Close">
							<Close className="w-3 h-3 md:w-4 md:h-4"/>
						</button>
					}
					{children}
				</div>
			</div>
		</div>
	)
}

Sidebar.defaultProps = {
	showSearchBar: true,
	showCloseButton: true,
}

export default Sidebar