import { getRegionCurrency } from "./region"

interface PresentmentPrice {
    currencyCode: string
    amount: string
}

function getPricesFromPresentmentNodes(edgesOrNodes: any[] | Record<string, any>) {
    if (!Array.isArray(edgesOrNodes)) {
        return edgesOrNodes.edges.map((edge: any) => edge.price)
    }

    return edgesOrNodes.map((edge: any) => edge.price)
  }

export function getPriceFromVariantPresentmentPrices(prices: PresentmentPrice[] = [], fallbackPrice = null) {
    const code = getRegionCurrency()
    const price = prices.find((varPrice) => varPrice.currencyCode === code)?.amount
  
    return price ? parseFloat(price) : fallbackPrice
  }

export function getPriceFromVariantPresentmentPriceNodes(nodes: any[] = [], fallbackPrice = null) {
    return getPriceFromVariantPresentmentPrices(getPricesFromPresentmentNodes(nodes), fallbackPrice)
  }