import React, { useContext, useEffect, useState } from "react"
import getValue from "get-value"
// import * as styles from "./upsell.module.css"
import { findInitialVariant } from "@src/utils/product"
import {GatsbyImage} from 'gatsby-plugin-image'
import StoreContext from "@context/store-context"
import {getImageData} from '@utils/image'
import { formatPrice } from "@utils/products/pricing"
import { Link } from "gatsby"
import { getUriFromDocument } from "@utils/routing"
import { useProductQuickView } from '@context/product-quick-view-context'

const colorMap = {
    "Cobalt Blue": "#394160",
    "Ash": "#EDEDED",
    "Grey": "#939393",
    "Charcoal": "#484848",
    "Navy": "#15353D",
    "Blush": "#EFB2A0",
    "Ice": "#BDDADE",
    "Turquoise": "#4CB7B4",
    "Blue": "#3D4971",
    "Navy Groov": "#15353D",
    "Blush Groov": "#EFB2A0",
    "Ice Groov": "#BDDADE",
    "Turquoise Groov": "#4CB7B4",
    "Sage Groov": "#E2E7C2",
    "Lilac Groov": "#EAE7F0",
    "Blue": "#3D4971",
    "Sand": "#E2D5C5",
    "Dark Grey": "#5E5d61",
    "Pine": "#3D685E",
    "That's My Jam": "#CE948C",
    "Thats My Jam": "#CE948C",
    "Health Nut": "#898D7D",
    "Gold N' Delicious": "#DDB688",
    "Gold N Delicious": "#DDB688",
    "Sage": "#E2E7C2",
    "Lilac": "#EAE7F0"
}

const getOptionTitle = variant => {
  const { selectedOptions } = variant
  const sizeMap = {
    S: "Small",
    M: "Medium",
    L: "Large",
  }
  const selectedOption = selectedOptions.find(option => option.name === "Size")
  const optionValue = getValue(selectedOption, "value")

  return getValue(sizeMap, `${optionValue}`)
}

// const EVENT_VIEWED_PRODUCT = "viewedProduct"
// const EVENT_ADDED_TO_CART = "addedToCart"

const UpsellItem = ({ index, product, currentSlide }) => {
  const isActive = index === currentSlide;
  const [buttonRef, setButtonRef] = useState(null)
  const { _type, shortTitle, title, mainImage, matchingVariants, key, slug, isBundle, productGroups, productOptions } = product
  const firstVariant = findInitialVariant(matchingVariants, false) || matchingVariants[0]
  const [selectedVariant, setSelectedVariant] = useState(firstVariant);
  const variantSlug = `?variantId=${selectedVariant.shopifyId}`
  const productUrl = getUriFromDocument({slug, productGroups, _type}) + (!isBundle ? variantSlug : '')

  const {
    shopifyIdBase64Encoded,
    size,
    price: variantPrice,
    color,
    backorder,
    listingImage,
  } = selectedVariant
  let imageData = getImageData(mainImage, { width: 100, height: 100, fit: 'crop' })
  let imageTitle = getValue(mainImage, "0.title")
  let imageAlt = mainImage?.asset?.altText ? mainImage?.asset?.altText : mainImage?.asset?.filename?.replace(/\.(.*)$/, '').replace(/[^a-zA-Z0-9]+/g, ' ');
  const variantImageTitle = getValue(listingImage, "0.title")
  const hasColor = color !== null
  const {
    toggleDrawer,
    store: { adding },
  } = useContext(StoreContext)
  
  if (listingImage) {
    imageData = getImageData(listingImage, { width: 100, height: 100, fit: 'crop' })
    imageTitle = variantImageTitle
    imageAlt = listingImage?.asset?.altText ? listingImage?.asset?.altText : listingImage?.asset?.filename?.replace(/\.(.*)$/, '').replace(/[^a-zA-Z0-9]+/g, ' ');
  }

  useEffect(() => {
    setSelectedVariant(firstVariant)
  }, [product])

  const buttonClass = `justify-between relative items-center px-4 py-2 md:py-1 border border-transparent text-sm leading-5 font-medium font-medium rounded-md focus:outline-none transition ease-in-out duration-150 antialiased disabled:cursor-not-allowed disabled:bg-dark-gray disabled:opacity-50`

  const {
    setQuickViewOpen,
    setQuickViewProduct,
    setQuickViewVariant,
    setQuickViewType,
    setQuickViewProductList,
  } = useProductQuickView();

  const openProductQuickView = () => {
    setQuickViewProduct(product);
    setQuickViewVariant(selectedVariant)
    setQuickViewOpen(true);
    setQuickViewType('product');
    setQuickViewProductList('Shopping Cart');
  };
  const startingAtPrice = Math.min.apply(Math, product?.variants.filter(variant => variant.price && variant.price != undefined).map(variant => variant.price));
  return (
        <div className="flex p-5 bg-calm-yellow sm:mb-0 flex-wrap rounded-xl" key={key} aria-label={'Upsell Item'} tabIndex={0}>
          <div className="flex flex-grow">
            <div className="w-20 md:w-24 rounded-xl overflow-hidden">
              <Link onClick={() => toggleDrawer()} to={productUrl}>
                  <GatsbyImage className={`w-20 h-20 md:w-24 md:h-24`} alt={imageAlt} image={imageData} />
              </Link>
            </div>
            <div className="flex flex-col ml-2 md:flex-grow justify-between">
              <p className="text-sm text-black w-[75%]">
              <Link onClick={() => toggleDrawer()} to={productUrl}>
                <strong className="font-medium">
                  Frequently purchased with {shortTitle || title}
                </strong>
              </Link>
                <br />
                {size && <span className="font-medium">Size:  <span className="font-normal">{size}</span></span>}
                {color && size && <span className="px-1">/</span>}
                {color && <span className="font-medium">Color: <span className="font-normal">{color}</span></span>}
              </p>
              {/* <div className="flex pt-2 mb-3">
                {hasColor &&
                  matchingVariants.map((variant) => (
                    <ColorOption
                      key={variant.shopifyId}
                      {...variant}
                      selected={selectedVariant?.shopifyId === variant.shopifyId}
                      onSelect={() => setSelectedVariant(variant)}
                    />
                  ))}
                  </div>*/}
              <button
                className={`${buttonClass} hidden max-w-full w-full md:inline-flex btn btn-primary`}
                type="button"
                disabled={adding}
                ref={setButtonRef}
                onClick={openProductQuickView}
                aria-label={`Select Upsell Options, starting from ${startingAtPrice} dollars`}
              >
              <span>Select Options</span>
              {formatPrice({price: startingAtPrice})}+
              </button>
            </div>
          </div>
          <button
              className={`${buttonClass} inline-flex w-full max-w-full md:hidden mt-3 btn btn-primary`}
              type="button"
              disabled={adding}
              ref={setButtonRef}
              onClick={openProductQuickView}
              aria-label={'Select Upsell Options'}
            >
              <span>Select Options</span>
              {formatPrice({price: startingAtPrice})}+
          </button>
        </div>
  )
}

export default UpsellItem
