import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import getValue from "get-value"
import { useGroupQueries, useGroupsQuery } from "@src/hooks/useGroupsQuery"

const documentTypeMap = new Map([
  [
    "category",
    document => {
      const { _id, slug, group } = document

      return `/${group}/${slug.current}/`
    },
  ],

  [
    "blogPost",
    document => {
      const { _id, slug, group , categories} = document

      if (categories) {
        return `/blog${categories[0]?.slug?.current ? "/" + categories[0]?.slug?.current + "/" : `${categories}/`}${slug.current}/`
      }

      return `/blog/${slug.current}`
    },
  ],
  [
    "product",
    document => {
      const { _id, slug, group } = document

      return `/collections/${slug.current}/`
    },
  ],
  [
    "shopifyProduct",
    document => {
      const { _id, _type, slug, group } = document
      
      if (document.productGroups && document.productGroups.length > 0) {
        let productGroup;

        if (document.productGroups[0]._type === "reference") {
          const { sanityProductGroups } = useGroupsQuery()

          const groups = getValue(sanityProductGroups, { default: [] })
          productGroup = groups?.find(group => group._id === document.productGroups[0]._ref)
        } else {
          productGroup = document.productGroups[0]
        }

        return `/collections/${productGroup?.slug?.current}/${slug.current}/`
      }

      return `/collections/${slug.current}/`
    },
  ],
  [
    "shopifyProductCanonical",
    document => {
      const { _id, slug, group } = document

      return `/products/${slug.current}/`
    },
  ],
  [
    "productGroup",
    document => {
      const { _id, slug, group } = document

      return `/collections/${slug.current}/`
    },
  ],
  [
    "resourcePost",
    document => {
      const { _id, slug, group } = document

      return `/resources/${slug.current}/`
    },
  ],
  [
    "simpleLandingPage",
    document => {
      const { _id, slug, group, blogCategory } = document

      if (blogCategory) return `/${slug.current}/${blogCategory}/` 

      return `/${slug.current}/`
    },
  ],
  [
    "landingPage",
    document => {
      const { _id, slug, group } = document

      if (slug && slug.current === "home") {
        return "/"
      }

      return `/${slug.current}/`
    },
  ],
])

export const getUriFromDocument = (document, canonical) => {
  const { _type } = document
  const type = canonical && _type === "shopifyProduct"
    ? documentTypeMap.get("shopifyProductCanonical") 
    : documentTypeMap.get(_type)

  if (!type) {
    return null
  }

  return type(document)
}

export const getUriByTypeAndSlug = (_type, slug , categories) => {
  const type = documentTypeMap.get(_type)

  if (!type) {
    return null
  }
  return type({ slug, categories })
}

export const getAbsoluteUrl = path => {
  if (!path) {
    path = ""
  }

  const siteBaseUrl = process.env.URL || "https://www.diggs.pet/"

  return `${siteBaseUrl}/${path}`.replace(/((?!:).|^)\/{2,}/g, (_, p1) => {
    if (/^(?!\/)/g.test(p1)) {
      return `${p1}/`;
    }

    return '/';
  });
}

export const getLinkDataFromCta = cta => {
  if (!cta) {
    return {}
  }

  const { externalLink, internalLink, text } = cta

  if (!cta || ((!externalLink || externalLink === "") && !internalLink)) {
    return {}
  }

  if (internalLink) {
    return {
      text,
      url: getUriFromDocument(internalLink),
      mode: "internal",
    }
  }

  return {
    text,
    url: externalLink,
    mode: "external",
  }
}

export const ensureUrlHasTrailingSlash = url => {
  url = url += url.endsWith("/") ? "" : "/"

  return url
}
