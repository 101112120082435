import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { uid } from "react-uid";
import OptionDropdown from "./dropdown";
import ColorSwatch from "./swatch";
import { getSelectedDisplayValue, filteredAndSortedOptions, findMatch, checkStatus } from "@utils/product";
import { ReactComponent as ArrowDown } from "@svgs/arrow-down.svg";
import { currencyOptionCompareAtPrice, currencyOptionPrice } from "@utils/products/pricing";
import SwatchItem from "./swatch/swatch-item";
import { animated, useSpring } from "react-spring"
import { onEnterKeyPress } from "@src/utils/general";
import { ReactComponent as PrimeIcon } from "@svgs/buy-with-prime.svg";
import StoreContext from "@src/context/store-context";
import AlertDialog from "@src/components/alert";
import useGlobalState from "@src/store";
import VariantChips from "./variant-chips";

const DEFAULT_TITLE_NAME = "Title";

const OptionSelector = ({
  currentVariant,
  options,
  productOptions,
  variants,
  handleOptionChange,
  hideInStickyATC,
  prices,
}) => {
  const filteredOptions = useMemo(() => filteredAndSortedOptions(options, variants), [
    options,
    variants,
  ]);

  const isDefaultVariant = filteredOptions.find(option => option.name === DEFAULT_TITLE_NAME);
  if (isDefaultVariant) return null;
  
  return (
    <div className="option-selector flex flex-col gap-y-2.5 md:gap-y-5 is-sticky-pt-0 relative top-0">
      {filteredOptions?.map((filteredOption, index) => (
        <OptionItem
          index={index}
          key={uid(filteredOption)}
          filteredOption={filteredOption}
          currentVariant={currentVariant}
          productOptions={productOptions}
          variants={variants}
          handleOptionChange={handleOptionChange}
          hideInStickyATC={hideInStickyATC}
        />
      ))}
    </div>
  );
}

const OptionItem = ({
  filteredOption,
  currentVariant,
  productOptions,
  variants,
  handleOptionChange,
  hideInStickyATC,
  index,
}) => {
  const { originalIndex, name, values } = filteredOption;
  const setAlert = useGlobalState((state) => state.setAlert);
  const isDefaultVariant = name === DEFAULT_TITLE_NAME;
  if (isDefaultVariant) return null;

  const selectedValue = useMemo(() =>
    currentVariant?.selectedOptions?.find(({ name: name1 }) => name1 === name)?.value,
    [currentVariant, name]
  );
  const otherValues = useMemo(() => 
    productOptions?.find(option => option?.optionName === name)?.optionValues?.map(value => value?.originalValueName)?.filter(value => value !== selectedValue),
    [currentVariant, name]
  )

  const [isOpen, setIsOpen] = useState(false)
  const toggleOptions = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const { isDisabled, isUnavailable, isOnSale, isAvailableWithPrime } = useMemo(
    () => checkStatus(name, selectedValue, variants, currentVariant),
    [name, selectedValue, variants, currentVariant]
  );

  const isColor = name === "Color" || name === "Flavor";
  const selectedDisplay = getSelectedDisplayValue(name, selectedValue, productOptions);

  const optionLabels = (isOnSale, isUnavailable, isAvailableWithPrime) => {
    if (isUnavailable) {
      if (isAvailableWithPrime && isOnSale) {
        return (
          <>
            <div className="px-1.5 py-0.5 w-fit bg-white rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
              <PrimeIcon />
            </div>
            <div className="px-1.5 py-0.5 w-fit bg-steadfast-red rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
              <div className="text-right text-white text-xs font-normal whitespace-nowrap">Sale</div>
            </div>
          </>
        );
      } else if (isAvailableWithPrime) {
        return (
          <div className="px-1.5 py-0.5 w-fit bg-white rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
            <PrimeIcon />
          </div>
        );
      } else {
        return (
          <div className="px-1.5 py-0.5 w-fit bg-[#F3F3F4] rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
            <div className="text-right text-soft-black text-xs font-normal whitespace-nowrap">Out of Stock</div>
          </div>
        );
      }
    } else if (isOnSale) {
      return (
        <div className="px-1.5 py-0.5 w-fit bg-steadfast-red rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
          <div className="text-right text-white text-xs font-normal whitespace-nowrap">Sale</div>
        </div>
      );
    }

    return null; // No label to show
  }

  const animateProps = useSpring({
    height: isOpen ? `auto` : 0,
    visibility: isOpen ? 'visible' : 'hidden',
    opacity: isOpen ? 1 : 0,
    from: { height: 0, visibility: 'hidden' },
    config: { tension: 400, friction: 30 },
  });

  useEffect(() => {
    if (isOpen) {
      setAlert(<AlertDialog>{`Option Selector for ${name} expanded`}</AlertDialog>)
    }
  }, [isOpen])

  return (
    <>    
    <div key={`${name}-selector`} className="dropdown relative w-full">
      <div
        className={`w-full justify-between p-4 relative bg-white flex flex-col bg-white border border-darker-gray gap-x-2 items-center cursor-pointer rounded-xl ${hideInStickyATC ? "is-sticky-hidden" : ""}`}
        onClick={toggleOptions}
        onKeyDown={(e) => onEnterKeyPress(e, () => setIsOpen(true))}
        // aria-controls={`tab-${originalIndex}`}
        // data-toggle="collapse"
        // aria-expanded={isOpen}
        tabIndex={0}
        // role="button"
        aria-label={`Option Selector for ${name}. Selected Value: ${selectedValue}. Other available values are ${otherValues?.join(', ')}.`}
      >
        <div className="w-full justify-between items-center gap-2.5 flex z-[1] relative">
          <div className="gap-2.5 flex md:flex-row flex-wrap md:max-w-[60%]">
            <p className="text-base flex font-medium gap-1 items-flex-start block">
              {name}: <span className="font-normal">{selectedValue}</span>
              {selectedDisplay !== selectedValue
                && <span className="font-normal">{selectedDisplay}</span>}

              {(name === 'Color' || name === 'Flavor') && (
                <SwatchItem name={name} value={selectedValue} compactWidth={true} />
              )}
            </p>
            {!isOpen && optionLabels(isOnSale, isUnavailable, isAvailableWithPrime)}
          </div>
          <div className="gap-2.5 flex items-center">
            <div className={`text-right text-neutral-900 text-base hidden lg:flex font-normal ${isOpen && '!hidden' || ''}`}>
              {(values.length - 1) !== 0 && ((values.length - 1) === 1
                ? `${values.length - 1} more option`
                : `${values.length - 1} more options`)}
            </div>
            <ArrowDown className="fill-soft-black shrink-0" />
          </div>
        </div>

        <animated.div
          tabIndex="-1"
          style={animateProps}
          className={`dropdown-content w-full bg-white flex flex-col justify-start items-start transition-all ease-out overflow-hidden`}
          // id={`tab-${originalIndex}`}
          // role="tabpanel"
        >
          {isColor ? (
            <ColorSwatch
              className="flex w-full gap-x-5 pt-5 pb-[2px] flex-wrap"
              option={filteredOption}
              handleOptionChange={(index, value) => handleOptionChange(index, value)}
              variants={variants}
              currentVariant={currentVariant}
            />
          ) : (
            <OptionDropdown
              option={filteredOption}
              handleOptionChange={(index, value) => handleOptionChange(index, value)}
              variants={variants}
              currentVariant={currentVariant}
              productOptions={productOptions}
              optionLabels={optionLabels}
            />
          )}
        </animated.div>
      </div>
    </div>

    <div className="w-full chips-holder hidden">
      <VariantChips 
        option={filteredOption}
        variants={variants}
        currentVariant={currentVariant}
        productOptions={productOptions}
        optionLabels={optionLabels}
        handleOptionChange={(index, value) => handleOptionChange(index, value)}
      />
    </div>
    </>
  );
};

export default OptionSelector;