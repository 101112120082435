import React from 'react';
import { checkOptionChecked, checkStatus } from "@utils/product";
import SwatchItem from './swatch-item';

const ColorSwatch = ({ variants, currentVariant, option, handleOptionChange, className, compactWidth, hideLabels }) => {
  if (!option) return <></>

  const { name, values, originalIndex } = option

  return (
    <div className={className}>
      {values?.map((value, index) => {
        const status = {
          ...checkStatus(name, value, variants, currentVariant),
          isChecked: checkOptionChecked(name, value, currentVariant)
        }

        return (
          <SwatchItem
            key={`swatch-${index}`}
            name={name}
            value={value}
            status={status}
            compactWidth={compactWidth}
            handleOptionChange={handleOptionChange}
            originalIndex={originalIndex}
            hideLabels={hideLabels}
          />
        );
      })}
    </div>
  );
}

export default ColorSwatch;
