import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Logo = ({ className }) => (
	<Link className={className} to="/" aria-label="Site Logo">
		<StaticImage
			src="../../images/logo.png"
			width={90} 
			objectFit="contain"
			alt="Site Logo"
			/>
	</Link>
)


export default Logo