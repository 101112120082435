const SanityClient = require("@sanity/client");

// exports.sanityClient = SanityClient({
// 	projectId: process.env.GATSBY_SANITY_PROJECT_ID,
// 	dataset: process.env.GATSBY_SANITY_DATASET,
// 	token: process.env.SANITY_TOKEN,
// 	apiVersion: "2022-08-08",
// 	useCdn: true,
// });

exports.sanityClient = SanityClient({
  projectId: 'yt8n50id',
  dataset: 'production-new',
  apiVersion: '2021-10-21', // use current UTC date - see "specifying API version"!
  // token: 'sanity-auth-token', // or leave blank for unauthenticated usage
  useCdn: false, // `false` if you want to ensure fresh data
})
