import React from 'react'
import { Link } from 'gatsby'
import { ReactComponent as Arrow } from "@svgs/arrow.svg"
import { ReactComponent as ArrowDown } from "@svgs/arrow-down.svg";
import { desktopButtonWidth, mobileButtonWidth, setButtonTheme } from './helpers'
import { linkProps } from '@utils/general'

const Button = ({ 
	theme, 
	internalLink, 
	externalLink, 
	type,
	title,
	buttonType,
	ctaWidth: width, 
	arrow, 
	onClick, 
	className, 
	children, 
	text, 
	disabled, 
	target,
	tabIndex,
	ariaLabel,
}) => {
	const { to, href }  = {...linkProps(internalLink, externalLink)}

	if (!to && !href && !children && !text) return

	const themeStyle = setButtonTheme(theme)

	if (buttonType || onClick) {
		return (
			<>
				<button type={buttonType} className={`btn ${className || ''} ${themeStyle?.mobile || ''} w-full ${mobileButtonWidth[width?.mobileWidth] || ''} flex items-center md:hidden`} onClick={onClick} disabled={disabled} tabIndex={tabIndex} aria-label={ariaLabel}>
					{children || text}
					{arrow !== 'none' && (arrow === 'down' ? <ArrowDown /> : <Arrow className={`ml-2 arrow ${arrow === 'left' ? 'rotate-180' : ''}`} />)}
				</button>
				<button type={buttonType} className={`btn ${className || ''} ${themeStyle?.desktop || ''} w-full ${desktopButtonWidth[width?.desktopWidth] || ''} flex items-center max-md:hidden`} onClick={onClick} disabled={disabled} tabIndex={tabIndex} aria-label={ariaLabel}>
					{children || text}
					{arrow !== 'none' && (arrow === 'down' ? <ArrowDown /> : <Arrow className={`ml-2 arrow ${arrow === 'left' ? 'rotate-180' : ''}`} />)}
				</button>
			</>
		)
	}
	return (
		<>
			<Link to={to} href={href} type={type} target={target} title={title} className={`btn ${className || ''} ${themeStyle?.mobile || ''} w-full ${mobileButtonWidth[width?.mobileWidth] || ''} flex items-center md:hidden`} tabIndex={tabIndex} aria-label={ariaLabel}>
				{children || text}
				{arrow !== 'none' && (arrow === 'down' ? <ArrowDown /> : <Arrow className={`ml-2 arrow ${arrow === 'left' ? 'rotate-180' : ''}`} />)}
			</Link>
			<Link to={to} href={href} type={type} target={target} title={title} className={`btn ${className || ''} ${themeStyle?.desktop || ''} w-full ${desktopButtonWidth[width?.desktopWidth] || ''} flex items-center max-md:hidden`} tabIndex={tabIndex} aria-label={ariaLabel}>
				{children || text}
				{arrow !== 'none' && (arrow === 'down' ? <ArrowDown /> : <Arrow className={`ml-2 arrow ${arrow === 'left' ? 'rotate-180' : ''}`} />)}
			</Link>
		</>
	)
}

Button.defaultProps = {
	theme: {
		desktop: 'btn-primary',
		mobile: 'btn-primary'
	},
	arrow: 'right',
	type: '',
	isFullWidth: true,
	width: {
		desktopWidth: 'fit',
		mobileWidth: 'fit',
	},
	disabled: false,
}

export default Button