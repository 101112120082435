import React from "react"
import PropTypes from "prop-types"
import Container from "@components/container"
import { FooterData, LinkSection, PrivacyLinks } from "./data"
import Section from "@components/section"

const Footer = ({ navigation, navigationBelow, socials }) => (
  <footer className="mt-20">
    <Section>
      <div className="grid grid-cols-2 gap-y-10 sm:gap-x-24 xl:gap-40 w-full sm:mx-auto sm:grid-cols-4 xl:col-span-4">
        {navigation
          .filter(group => group?.childNodes?.length > 0)
          .map(group => {
            return (
              <LinkSection key={React.useId()} links={group.childNodes} title={group.title} />
            )
          })}

        {/* Footer Social links */}
        <LinkSection links={socials} title={"Keep In Touch"} />
      </div>
    </Section>

    <Section>
      <PrivacyLinks links={navigationBelow} />
    </Section>
  </footer>
)

export default Footer
