import { useEffect, useState } from "react";

export default function useJustUnoBannerState(isCartOpened = false) {
  const [hasTopBanner, setHasTopBanner] = useState(false)
  const rootFrameClass = "ju_Con"
  const iframeClass = "ju_iframe"

  useEffect(() => {
    let observer: MutationObserver;
    const currentRoot = document.querySelector<HTMLDivElement>(`.${rootFrameClass}`)
    const currentFrame = document.querySelector<HTMLIFrameElement>(`.${iframeClass}`)

    if (currentFrame) {
      const isRootHidden = currentRoot?.style?.display === 'none'
      const topPositionBanner =
        currentFrame?.contentDocument?.body?.querySelector(
          '[data-direction="to top"]'
        )

      if (topPositionBanner && !hasTopBanner && !isRootHidden) {
        setHasTopBanner(true)
      }

      if (hasTopBanner && isRootHidden) {
        setHasTopBanner(false)
      }
    }

    // // Start waiting for it to be added
    if (currentRoot && isCartOpened) {
      const config = {
        attributes: true, 
        attributeFilter: ['style'],
      }
      observer = new MutationObserver((mutationList) => {
        mutationList.forEach((mutation) => {
          if(mutation.attributeName === 'style'){
            
            if (mutation.target instanceof HTMLElement && mutation.target.style?.display === 'none' && hasTopBanner) {
              setHasTopBanner(false)
            }
            // Disconnect maybe once it is hidden?
          }
        })
      })
  
      // Start observing the target node for configured mutations
      observer.observe(currentRoot, config);
    }


    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [isCartOpened])

  return {
    hasTopBanner,
  }
}
