import React, { useEffect, useState } from "react"
import { ReactComponent as OutOfStockIcon } from "@svgs/out-of-stock.svg";
import { ReactComponent as PrimeIcon } from "@svgs/buy-with-prime.svg";
import { onEnterKeyPress } from "@src/utils/general";
import { sanityClient } from "@src/build/sanity";

const SwatchItem = ({ name, value, status, handleOptionChange, originalIndex, compactWidth = false, hideLabels, insideOfButton }) => {
  const [queryData, setQueryData] = useState()

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "productColor"] {
        _id,
        colorName,
        color {
          hex
        }
      }`)
      .then((res) => setQueryData(res))
      .catch((err) => console.error(err));
  }, [])

  const getColorMapping = () => {
    const colorMapping = {};
    const colorNodes = queryData || [];

    colorNodes.forEach(({ color, colorName }) => {
      colorMapping[colorName] = color?.hex;
    });

    return colorMapping;
  };

  const colorMapping = getColorMapping();

  const { isDisabled, isUnavailable, isChecked, isOnSale, isAvailableWithPrime } = status;
  const backgroundColor = colorMapping[value] || value;

  return (
    <>
      <label 
        className={`relative inline-flex h-full cursor-pointer top-[2px] ${compactWidth ? 'drop-shadow' : ''}`} 
        data-option-type={'option-color'}
        key={value} 
        tabIndex={compactWidth ? -1 : 0} 
        aria-hidden={false}
        aria-label={`${isOnSale ? 'On Sale: ' : ''} ${isUnavailable ? 'Out of Stock: ' : ''} ${name} ${insideOfButton ? '' : value}  ${isChecked ? ', selected' : ''}`} 
        onKeyDown={(e) => {
          onEnterKeyPress(e, () => handleOptionChange(originalIndex, {target: {value}}))
        }}
      >
        <input
          className={`absolute opacity-0 cursor-pointer w-full h-full top-0 left-0 z-[1] ${isDisabled ? 'cursor-not-allowed' : ''}`}
          type="radio"
          name={name}
          value={value}
          checked={isChecked}
          onChange={(event) => handleOptionChange(originalIndex, event)}
          disabled={isDisabled}
          tabIndex={-1}
        />
        <div className={`rounded-full flex justify-center items-center transition is-sticky-w-8 ${compactWidth
          ? 'w-5 h-5'
          : 'w-10 h-10'
          } ${isChecked && !compactWidth ? "boder-solid border-1 bordrer-soft-black" : ''}`}
          title={value}
          aria-hidden='true'
          tabIndex={-1}
        >
          {isOnSale && !isUnavailable && !hideLabels && (
            <div className="absolute -top-2 -right-2 px-1.5 py-0.5 bg-steadfast-red rounded-xl justify-center items-center gap-2 flex z-[1]">
              <div className="text-right text-white text-xs font-normal whitespace-nowrap">Sale</div>
            </div>
          )}
          {!isAvailableWithPrime && isUnavailable && !compactWidth &&
            <span className="absolute w-5 h-5 bg-white rounded-full flex justify-center items-center z-0">
              <OutOfStockIcon />
            </span>
          }
          {isUnavailable && isAvailableWithPrime &&
            <div className="absolute -top-2 -right-2 px-1.5 py-0.5 bg-white rounded-xl justify-center items-center gap-2 flex z-[1] shadow-md">
              <PrimeIcon />
            </div>
          }
          <span className={`inline-flex rounded-full ${compactWidth
            ? 'w-4 h-4'
            : 'w-8 h-8'
            } is-sticky-w-6`} style={{ backgroundColor }}></span>
        </div>
      </label>
    </>
  );
}

SwatchItem.defaultProps = {
  status: {
    isDisabled: false,
    isUnavailable: false,
    isChecked: false,
    isOnSale: false,
  },
  compactWidth: false,
  hideLabels: false,
  insideOfButton: false,
  handleOptionChange: () => {},
};

export default SwatchItem;
