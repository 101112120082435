import React, { useEffect, useRef } from 'react'
import { animated, useSpring } from "react-spring"
import Column from '@components/column'
import Field from '@components/form/field'
import { useForm } from 'react-hook-form'
import Button from '@components/button'
import { useSearchContext } from '@context/search-context'
import { ReactComponent as SearchIcon } from '@svgs/search.svg'
import { ReactComponent as Close } from "@svgs/close.svg";
import ColumnItem from '@components/rebrand/column-item'
import SearchResults from '../search-results'
import { onEnterKeyPress } from '@src/utils/general'
import { trackViewSearchResults } from '@src/services/analytics'
import { getUriFromDocument } from '@src/utils/routing'
import useSearchQueries from '@src/hooks/useSearchQueries'

const SearchModal = ({ products, blogPosts, resourcesPosts, groups, search }) => {
  const formRef = useRef();

  const {
    searchState,
    setSearchState,
    toggleSearch,
    onSubmit,
    onInputFocus,
    clearSearch
  } = useSearchContext()

  const { register, handleSubmit, setValue } = useForm()
  const { isOpen, items, latestItems, query } = searchState;
  const { defaultGroups, defaultProducts, defaultBlogs } = search;
  const { getItems, getLatestItems } = useSearchQueries();

  const searchAnimateProps = useSpring({
    transform: `translateX(-50%) translateY(${isOpen ? 0 : -50}%)`,
    opacity: isOpen ? 1 : 0,
    delay: 0.2,
    visibility: isOpen ? 'visible' : 'hidden',
    from: { opacity: 0, visibility: 'hidden' }
  })

  useEffect(() => {
    setSearchState(prevState => {
      const items = {
        products,
        blogs: [...blogPosts, ...resourcesPosts],
        groups
      };
      const latestItems = {
        products: defaultProducts,
        blogs: defaultBlogs,
        groups: defaultGroups,
      };

      const { items: newItems, closestWordToQuery } = getItems(prevState.query, items);
      const newLatestItems = getLatestItems(prevState.latestQueries, items, latestItems);

      if (JSON.stringify(newItems) !== JSON.stringify(prevState.items) || 
          JSON.stringify(newLatestItems) !== JSON.stringify(prevState.latestItems)) {
        return {
          ...prevState,
          items: newItems,
          latestItems: newLatestItems,
          closestWordToQuery,
        };
      }

      return prevState;
    });
  }, [searchState.query, searchState.latestQueries])
  
  useEffect(() => {
    if (isOpen) {
      document.activeElement.blur();
      formRef.current.focus();
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && query && query !== '') {

      const visibleItems = items.products.length > 0 ? items : latestItems

      trackViewSearchResults(visibleItems.products.flatMap(product => {
        const isBundle = product.isBundle;
        const variant = !isBundle ? product.variants[0] : [];

        if (isBundle) {
          const bundleProducts = product?.bundleProductsList;
          
          return bundleProducts.map(bundleProduct => {
            const bundleVariant = bundleProduct?.variants[0];

            return {
              merchandise: {
                id: bundleVariant.shopifyId,
                sku: bundleVariant.sku,
                title: bundleVariant.variantTitle,
                price: bundleVariant.price,
                compareAtPrice: bundleVariant.compareAtPrice,
                list: 'search results',
                product: {
                  id: bundleProduct.shopifyId,
                  title: bundleProduct.title,
                  category: bundleProduct.productGroups?.map(group => group.title),
                },
              },
            }
          });
        }

        return {
          merchandise: {
            id: variant.shopifyId,
            sku: variant.sku,
            title: variant.variantTitle,
            price: variant.price,
            compareAtPrice: variant.compareAtPrice,
            list: 'search results',
            product: {
              id: product.shopifyId || product.yotpoProductId,
              title: product.title,
              onlineStoreUrl: getUriFromDocument(product),
              category: product.productGroups?.map(group => group.title),
              list: 'search results',
            },
          },
        }
      }))
    }
  }, [isOpen, items])

  return (
    <animated.div
      className={`fixed max-w-4xl w-full px-0 md:px-5 animate-fadein transition-visibility top-[162px] md:top-[142px] left-1/2 search-modal overflow-y-auto rounded-xl `}
      tabIndex="0"
      aria-hidden={false}
      aria-label='Search Modal'
      style={searchAnimateProps}
    >
      <div className='bg-white w-full rounded-xl p-5 flex gap-5 flex-col'>
        <Column innerClassName="min-w-full !p-0 md:!flex-row relative max-w-full">
          <ColumnItem className="max-w-xl mx-auto order-2 !gap-y-2.5">
            <form className='flex gap-x-2.5' onSubmit={handleSubmit(onSubmit)} ref={formRef} tabIndex={0} aria-hidden='false' aria-label='Search Form'>
              <div className='w-full flex border-1 border-solid border-darker-gray rounded-xl px-5 py-3 items-center gap-x-2.5 md:gap-x-5 group'>
                <SearchIcon className="w-7 min-w-7 flex flex-shrink-0" />
                <Field
                  name="search"
                  type="search"
                  placeholder="search products..."
                  register={register}
                  className="w-full p-0 justify-center search-field shrink w-[inherit]"
                  onFocus={onInputFocus}
                />
                <Close className="w-3 h-3 cursor-pointer invisible group-hover:visible" onClick={() => clearSearch(setValue)} onKeyDown={(e) => onEnterKeyPress(e, () => clearSearch(setValue))} tabIndex={0} aria-label={'Clear Search'} role={'button'} />
              </div>
              <Button 
                theme="btn-secondary"
                ctaWidth={{desktopWidth:'fit', mobileWidth: 'fit'}}
                className="!pl-2 !pr-4 pl-7 pr-9"
                buttonType={'submit'}
                ariaLabel={'Submit search'}
              >
                <></>
              </Button>
            </form>
            {searchState.closestWordToQuery && (
              <p className='px-4 py-1 rounded-lg w-fit text-sm font-normal inline bg-light-gray'>
                Did you mean &quot;
                <span className='text-safety-green'>{searchState.closestWordToQuery}</span>
                &quot;?
              </p>
            )}
          </ColumnItem>
          <ColumnItem className={'md:!w-0 max-md:items-end'}>
            <Close className="cursor-pointer w-3 h-3 md:w-4 md:h-4 md:absolute top-0 right-0" onClick={toggleSearch} onKeyDown={(e) => onEnterKeyPress(e, toggleSearch)} tabIndex={0} aria-label={'Close Modal'} role={'button'} />
          </ColumnItem>
        </Column>

        <SearchResults searchItems={searchState.items} query={searchState.query} focused={searchState.isFocused} latestSearchItems={searchState.latestItems} />
      </div>
    </animated.div>
  )
}

export default SearchModal