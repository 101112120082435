import React, { createContext, useContext, useEffect, useState } from 'react';
import { toggleBodyScrollLock } from '@utils/general'
import Backdrop from '@components/sidebar/backdrop';

const SidebarContext = createContext();

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const toggleSidebar = override => override
    ? setSidebarOpened(override.override)
    : setSidebarOpened((prevState) => !prevState)

  useEffect(() => {
    toggleBodyScrollLock('body', sidebarOpened);
  }, [sidebarOpened]);

  return (
    <SidebarContext.Provider value={{ sidebarOpened, toggleSidebar, setSidebarOpened }}>
      {children}
      <Backdrop opened={sidebarOpened} onClick={() => toggleSidebar({override: false})} />
    </SidebarContext.Provider>
  );
};
