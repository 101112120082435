import client from "@src/api/sanity/client";
import { getRegionHandle } from "@src/utils/region";
import { navigate } from "gatsby";
import { encode } from "shopify-gid"

export const checkForCashbackProducts = async (cart, addLineItemsToCart) => {
  const cashbackCode = new URLSearchParams(location.search).get("cashback");
  
  if (cashbackCode) {
    const list = {
      "15back": "gid://shopify/ProductVariant/43356769878202",
      "cb20": "gid://shopify/ProductVariant/43227389296826",
      "30cb": "gid://shopify/ProductVariant/43227393884346"
    }

    if (list[cashbackCode]) {
      const lines = [{
        merchandiseId: btoa(list[cashbackCode]),
        attributes: {
          _preorder: "false",
          _backorder: "false",
          _region: getRegionHandle(),
          _category: "Cashback Product",
          _list: "Shopping Cart",
          _cashback: "true",
        },
        quantity: 1,
      }];

      if (!cart.lines.find(item => item.merchandise.id === list[cashbackCode])) {
        await addLineItemsToCart(lines);
        navigate('/');
      }
    }
  }
};

export const captureOctaneAddToCart = (addLineItemsToCart, toggleDrawer) => {
  const octaneAddToCartEvent = (e) => {
    const products = e?.detail?.items;

    if (products?.length > 0) {
      processProducts(products?.[0]).then(lineItems => {
        addLineItemsToCart(lineItems).then(() => toggleDrawer({ override: true }));
      });
    }
  }

  if (window.octaneAddToCartEventHandler) {
    document.removeEventListener('octane.quiz.addToCart', window.octaneAddToCartEventHandler, false);
  }

  window.octaneAddToCartEventHandler = octaneAddToCartEvent
  document.addEventListener('octane.quiz.addToCart', window.octaneAddToCartEventHandler, false);
}

export const captureOctaneViewCart = (toggleDrawer) => {
  const octaneViewCartEvent = () => {
    toggleDrawer({ override: true })
  }

  if (window.octaneViewCartEventHandler) {
    document.removeEventListener('octane.quiz.viewCart', window.octaneViewCartEventHandler, false);
  }

  window.octaneViewCartEventHandler = octaneViewCartEvent
  document.addEventListener('octane.quiz.viewCart', window.octaneViewCartEventHandler, false);
}

export const captureOctaneViewCheckout = (goToCheckout) => {
  const octaneViewCheckoutEvent = () => {
    goToCheckout();
  }

  if (window.octaneViewCheckoutEventHandler) {
    document.removeEventListener('octane.quiz.viewCheckout', window.octaneViewCheckoutEventHandler, false);
  }

  window.octaneViewCheckoutEventHandler = octaneViewCheckoutEvent
  document.addEventListener('octane.quiz.viewCheckout', window.octaneViewCheckoutEventHandler, false);
}



export const fetchSanityProduct = async (productId) => {
  return await client.fetch(`*[_type == "shopifyProduct" && isBundle != true && enabled == true && "${productId}" in variants[]._ref][0] {
    ...,
    mainImage {
      asset -> {
        _id,
        originalFilename,
        "filename": originalFilename,
        title,
        altText,
      },
      _key,
      _type,
    },
    variants[_ref == "${productId}"] -> {
      ...,
      variantComponents[] -> {
        ...
      }
    },
    productGroups[] -> {
      _id,
      title,
      _type,
      slug {
        current
      },
    },
    productImages[] {
      image {
        _key,
        _type,
        asset -> {
          _id,
          originalFilename,
          "filename": originalFilename,
          title,
          altText,
        }
      }
    }
  }`);
};

export const processProducts = async (product) => {
  const sanityProduct = await fetchSanityProduct(product?.id);

  const variantComponents = sanityProduct?.variants?.[0]?.variantComponents;
  const lineItems = [];

  if (variantComponents?.length > 0) {
    const componentId = Math.random();

    lineItems.push(...variantComponents.map(component => {
      return {
        merchandiseId: encode("ProductVariant", component?._id),
        quantity: 1,
        attributes: {
          _preorder: "false",
          _backorder: "false",
          _region: getRegionHandle(),
          _componentOf: componentId.toString(36),
          _category: "Octane Quiz",
          _list: 'Octane Quiz',
        },
      }
    }));
  } else {
    lineItems.push({
      merchandiseId: encode("ProductVariant", product?.id),
      quantity: 1,
      attributes: {
        _preorder: "false",
        _backorder: "false",
        _region: getRegionHandle(),
        _category: "Octane Quiz",
        _list: 'Octane Quiz',
      },
    });
  }

  return lineItems;
};