import React, { useContext } from "react"
import classnames from "classnames"
import { useAddToCartContext } from "@context/add-to-cart-context"
import { getCurrentRegion } from "@utils/region";
import { ReactComponent as ShippingIcon} from "@svgs/shipping-truck.svg"
import ScreenReaderText from "@src/components/screen-reader-text";

const ShippingConditions = ({ productEntry, currentEntryVariant, hideBackorderDetails, className }) => {
  const {
    store: { currentVariant },
  } = useAddToCartContext()
  const region = getCurrentRegion().isoCode

  let isPreorder, isBackorder;
  let {
    shippingTimeEstimate,
    backOrderDetailsClassName,
    variants,
  } = productEntry

  let currVariant = currentEntryVariant || currentVariant
  if (currVariant) {
    const {
      status,
      shippingTimeEstimate: variantShippingTimeEstimate,
      shippingTimeEstimateCA: variantShippingTimeEstimateCA,
    } = currVariant

    isPreorder = status?.preorder
    isBackorder = status?.backorder

    const variantBackOrderDetailsClassName = status?.backOrderDetailsClassName

    if (variantShippingTimeEstimate || (region === "CA" && variantShippingTimeEstimateCA)) {
      shippingTimeEstimate = region === "CA" && variantShippingTimeEstimateCA
        ? variantShippingTimeEstimateCA
        : variantShippingTimeEstimate 
    }
    
    if (variantBackOrderDetailsClassName) {
      backOrderDetailsClassName = variantBackOrderDetailsClassName
    }
  }

  const klaviyoTriggerId = currVariant?.status?.klaviyoTriggerId;

  if (!isPreorder && !shippingTimeEstimate && !backOrderDetailsClassName) {
    return <></>
  }

  const orderDetails = () => {
    if (isPreorder){
      return 'Pre-order Details'
    } else if (isBackorder) {
      return 'Back-order Details'
    }

    return false
  }

  return (
    <div className={`text-soft-black ${className}`} tabIndex={0}>
      <div className="flex gap-2.5 items-center">
        <ShippingIcon className="shrink-0" />
        <p className="inline-block font-owners-text font-medium text-safety-green">{shippingTimeEstimate}</p>
        </div>
      {klaviyoTriggerId && orderDetails() && !hideBackorderDetails && (
        <>
          <button
            className={classnames(["underline inline klaviyo-retrigger", backOrderDetailsClassName])}
            onClick={(e) => {
              e.preventDefault();
              if (klaviyoTriggerId){
                klaviyo.openForm(klaviyoTriggerId);
              }
            }}
          >
            <strong>{orderDetails()}</strong>
          </button>
        </>
      )}
    </div>
  )
}

ShippingConditions.defaultProps = {
  className: '',
}

export default ShippingConditions
