import useGlobalState from '@src/store'
import React from 'react'

const DisplayedAlert = () => {
  const alert = useGlobalState((state) => state.alert);
  
  return (
    <div aria-live="polite">
      {alert || <></>}
    </div>
  )
}

export default DisplayedAlert