import React, { useState, useEffect } from "react"
import { getRegionCurrency, getRegionIsoCode } from "@utils/region"
import Context from "./store-context"
import { GraphQLClient } from "graphql-request"
import Client from "shopify-buy"

const CART_KEY = "shopify_cart_id"
const currencyCode = getRegionCurrency()
const regionIsoCode = getRegionIsoCode()
const isBaseCurrency = currencyCode === "USD"
const storefrontAccessToken = process.env.SHP_TOKEN
const domain = process.env.SHOP_DOMAIN
const apiVersion = process.env.SHOP_API_VERSION || "2023-07"
const shopifyStorefrontEndpoint = `https://${domain}/api/${apiVersion}/graphql`
const headers = {
  accept: "application/json",
  "X-SDK-Variant": "javascript",
  "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
  "Accept-Language": "*",
}
const graphQLClient = new GraphQLClient(shopifyStorefrontEndpoint, { headers })
const client = Client.buildClient({
  storefrontAccessToken,
  domain,
})

function getCartKey() {
  return isBaseCurrency ? CART_KEY : `$${CART_KEY}_${regionIsoCode}`
}

function replaceContextCountry(query) {
  query.definitions.find(definition => definition.kind == "OperationDefinition").directives[0].arguments[0].value.value = regionIsoCode

  return query
}

function isEmpty(input) {
  return !input || input.length === 0 || input === 'null' || input === 'undefined';
}

// Cart Object
import cartNodeQuery from "./graphql/cart/cartNodeQuery.graphql"
import cartCreateMutation from "./graphql/cart/cartCreateMutation.graphql"
import cartLinesAddMutation from "./graphql/cart/cartLinesAddMutation.graphql"
import cartLinesRemoveMutation from "./graphql/cart/cartLinesRemoveMutation.graphql"
import cartLinesUpdateMutation from "./graphql/cart/cartLinesUpdateMutation.graphql"
import cartDiscountCodesUpdateMutation from "./graphql/cart/cartDiscountCodesUpdateMutation.graphql"
import cartAttributesUpdateMutation from "./graphql/cart/cartAttributesUpdateMutation.graphql"

import { getCheckoutAttributes } from "@src/analytics/visitorInfo"
import { productsData } from "@src/services/analytics"
import useGlobalState from "@src/store"
import AlertDialog from "@src/components/alert"

const ShopifyContextProvider = ({ children }) => {
  let initialStoreState = {
    client,
    adding: false,
    removing: false,
    checkout: { lineItems: [] },
    cart: { lines: [] },
    products: [],
    shop: {},
    isDrawerOpen: false,
  }

  const [store, updateStore] = useState(initialStoreState)
  const setAlert = useGlobalState((state) => state.setAlert);
  // const [createCheckout] = useMutation(checkoutCreateMutation)

  const updateCartAttributes = async (cartId) => {
    const { cart } = store;

    try {
      const response = await graphQLClient
        .request(cartAttributesUpdateMutation, {
          cartId: cartId || cart.id,
          attributes: getCheckoutAttributes()
        })
        .then(data => {
          return data
        })

      const updatedCheckout = response?.cartAttributesUpdate?.cart

      updateStore(prevState => {
        return { ...prevState, checkout: updatedCheckout, adding: false }
      })

      return updatedCheckout;
    } catch (e) {
      console.error(e)
      return null
    }
  }

  const fixCartLineEdges = (cart) => {
    return {
      ...cart,
      lines: cart.lines.edges.map(edge => {
        return {
          ...edge.node,
        }
      })
    }
  }

  const setCartInfos = (cart) => {
    const cartLines = cart.lines.map(line => (
      {
        ...line, 
        merchandise: {
          ...line.merchandise, 
          product: {
            ...line.merchandise.product,
            list: line.attributes?.find(attribute => attribute.key === '_list')?.value,
            category: line.attributes?.find(attribute => attribute.key === '_category')?.value,
          }
        },
      }
    ));
    
    localStorage.setItem(
      "cartInfos",
      JSON.stringify({
        cartTotal: cart?.cost?.totalAmount?.amount || "0.0",
        lines: productsData("dl_user_data", cartLines) || {},
        cost: cart?.cost,
        discountAllocations: cart?.discountAllocations,
      })
    )
  }

  useEffect(() => {
    setCartInfos(store?.cart)
  }, [store?.cart])

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== "undefined"

      const existingCartID = isBrowser
        ? localStorage.getItem(getCartKey())
        : null

      const setCartInState = cart => {
        if (isBrowser) {
          localStorage.setItem(getCartKey(), cart.id)
        }

        updateStore(prevState => {
          return { ...prevState, cart }
        })
      }

      // Create New Cart
      const createNewCart = async () => {
        try {
          const response = await graphQLClient
            .request(replaceContextCountry(cartCreateMutation), {
              input: {
                buyerIdentity: {
                  countryCode: regionIsoCode,
                },
                attributes: getCheckoutAttributes(),
              }
            })
            .then(data => {
              return data
            })

          return fixCartLineEdges(response?.cartCreate?.cart)
        } catch (e) {
          console.error(e)
          return null
        }
      }

      const fetchCart = async id => {
        try {
          const response = await graphQLClient
            .request(replaceContextCountry(cartNodeQuery), {
              id
            })
            .then(data => {
              return data
            })

          return fixCartLineEdges(response?.cart)
        } catch (e) {
          console.error(e)
          return null
        }
      }

      const hasExistingCartId = !isEmpty(existingCartID)
      if (hasExistingCartId) {
        try {
          const cart = await fetchCart(existingCartID)

          setCartInfos(cart)
          setCartInState(cart)
          updateCartAttributes(existingCartID)

          return
        } catch (e) {
          localStorage.setItem(getCartKey(), null)

          const newCart = await createNewCart()

          setCartInfos(newCart);
          setCartInState(newCart);

          return
        }
      } else {
        const newCart = await createNewCart()

        setCartInfos(newCart);
        setCartInState(newCart);

        return
      }
    }

    initializeCheckout()
  }, [])

  return (
    <Context.Provider
      value={{
        store,
        toggleDrawer: override => {
          let { isDrawerOpen } = store

          isDrawerOpen = !isDrawerOpen

          if (override) {
            isDrawerOpen = override.override
          }

          updateStore(prevState => {
            return { ...prevState, isDrawerOpen }
          })
        },
        addLineItemsToCart: async (lines) => {
          updateStore(prevState => {
            return { ...prevState, adding: true }
          })

          setAlert(<AlertDialog>Product was added to cart</AlertDialog>)

          const { cart } = store
          const cartId = cart.id

          lines.forEach((line) => {
            line.quantity = parseInt(line.quantity, 10),
            line.attributes = [
              ...Object.keys(line.attributes).map(key => {
                return {
                  key: key,
                  value: line.attributes[key],
                }
              }), {
                key: "_quantity", value: String(line.quantity)
              }
            ]
          })

          try {
            const response = await graphQLClient
              .request(replaceContextCountry(cartLinesAddMutation), {
                cartId,
                lines, 
              })
              .then(data => {
                return data
              })

            const updatedCart = fixCartLineEdges(response?.cartLinesAdd?.cart)

            updateStore(prevState => {
              return { ...prevState, cart: updatedCart, adding: false }
            })
            return updatedCart
          } catch (e) {
            console.error(e)
            return null
          }
        },
        removeLineItems: async (cartId, ...lineIds) => {
          updateStore(prevState => {
            return { ...prevState, removing: true }
          })

          try {
            const response = await graphQLClient
              .request(cartLinesRemoveMutation, {
                cartId,
                lineIds,
              })
              .then(data => {
                return data
              })

            const updatedCart = fixCartLineEdges(response?.cartLinesRemove?.cart)

            updateStore(prevState => {
              return { ...prevState, cart: updatedCart, removing: false }
            })
          } catch (e) {
            console.error(e)
            return null
          }
        },
        updateLineItems: async (cartId, lines) => {
          // const linesToUpdate = lines.map((line) => {
          //   line.attributes.forEach(attr => 
          //     attr.key === "_quantity" &&
          //       (attr.value = String(line.quantity))
          //   )
          //   return ({
          //     ...line,
          //     attributes: line.attributes
          //   })
          // })

          try {
            const response = await graphQLClient
              .request(cartLinesUpdateMutation, {
                cartId,
                lines,
              })
              .then(data => {
                return data
              })

            const updatedCart = fixCartLineEdges(response?.cartLinesUpdate?.cart)

            updateStore(prevState => {
              return { ...prevState, cart: updatedCart, updating: false }
            })
          } catch (e) {
            console.error(e)
            return null
          }
        },
        addDiscount: async discountCodes => {
          const { cart } = store
          const cartId = cart.id

          try {
            const response = await graphQLClient
              .request(cartDiscountCodesUpdateMutation, {
                cartId,
                discountCodes: [discountCodes],
              })
              .then(data => {
                return data
              })

            const updatedCart = fixCartLineEdges(response?.cartDiscountCodesUpdate?.cart)

            if (response && response.cartDiscountCodesUpdate.userErrors?.length !== 0) {
              updateStore(prevState => {
                return {
                  ...prevState,
                  cart: {
                    ...prevState.cart,
                    userErrors: response.cartDiscountCodesUpdate.userErrors
                  },
                  updating: false
                }
              })
            } else {
              updateStore(prevState => {
                return { ...prevState, cart: updatedCart, updating: false }
              })
            }

            return response.cartDiscountCodesUpdate
          } catch (e) {
            console.error(e)
            return null
          }
        },
        removeDiscount: async () => {
          const { cart } = store
          const cartId = cart.id

          try {
            const response = await graphQLClient
              .request(cartDiscountCodesUpdateMutation, {
                cartId,
              })
              .then(data => {
                return data
              })


            const updatedCart = fixCartLineEdges(response?.cartDiscountCodesUpdate?.cart)

            updateStore(prevState => {
              return { ...prevState, cart: updatedCart, updating: false }
            })
          } catch (e) {
            console.error(e)
            return null
          }
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default ShopifyContextProvider

export const useLineItems = () => {
  const {
    store: { cart },
  } = React.useContext(Context)
  const items = cart ? cart.lines : []

  return items
}

export const useSubtotal = () => {
  const {
    store: { cart },
  } = React.useContext(Context)

  return cart?.cost?.totalAmount || {
    amount: 0,
    currencyCode: getRegionCurrency(),
  }
}

export const useDiscountTotal = () => {
  const {
    store: { cart },
  } = React.useContext(Context)

  const lineItemsSubtotal = parseFloat(cart?.cost?.subtotalAmount?.amount) || 0
  const subtotal = parseFloat(cart?.cost?.totalAmount?.amount) || 0

  return Math.max(0, lineItemsSubtotal - subtotal)
}

export const useHashedEmail = () => {
  const {
    store: { cart },
  } = React.useContext(Context)

  return cart?.email || ''
}