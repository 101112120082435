import React, { useMemo, useState } from 'react';
import Image from '@components/image';
import ColumnItem from '@components/rebrand/column-item';
import Button from '@components/button';
import { Link } from 'gatsby';
import { getUriFromDocument } from '@utils/routing';
import { formatItemsByType, gridCols, mobileGridCols, test } from '../helpers';
import { useIsMobile } from '@utils/general';
import { useSearchContext } from '@context/search-context';
import { trackSelectItem } from '@src/services/analytics';
import { getRegionShopUrl } from '@src/utils/region';

const SearchItem = ({
	heading,
	items,
	query,
	defaultItems,
	showMoreLink = false,
	length,
	mbLength,
	cols,
	mbCols,
	card = false,
	className,
}) => {
	const hasDefaultItems = !query || query === '';
	
	const formattedItems = formatItemsByType(hasDefaultItems ? defaultItems : items);

	const itemIds = formattedItems?.map((item) => item._id);
	const itemLength = useIsMobile() ? mbLength : length;
	const { toggleSearch } = useSearchContext();

	const trackItem = (item) => {
		if (item._type === 'shopifyProduct') {
			const variant = item.variants[0];
			const isBundle = item.isBundle;

			if (isBundle) {
				trackSelectItem(item.bundleProductsList.map(product => {
					const bundleVariant = product.variants[0]
					return {
						merchandise: {
							id: bundleVariant.shopifyId,
							sku: bundleVariant.sku,
							title: bundleVariant.variantTitle,
							price: bundleVariant.price,
							compareAtPrice: bundleVariant.compareAtPrice,
							image: {
								src: '',
							},
							product: {
								id: product.shopifyId || product.yotpoProductId,
								title: product.title,
								onlineStoreUrl: getRegionShopUrl() + getUriFromDocument(product)
							},
						},
					}
				}), 'search results')
			} else {
				trackSelectItem([{
					merchandise: {
						id: variant.shopifyId,
						sku: variant.sku,
						title: variant.variantTitle,
						price: variant.price,
						compareAtPrice: variant.compareAtPrice,
						image: {
							src: '',
						},
						product: {
							id: item.shopifyId || item.yotpoProductId,
							title: item.title,
							onlineStoreUrl: getRegionShopUrl() + getUriFromDocument(item)
						},
					},
				}], 'search results')
			}
		}
	}
	
	return (
		<>
			<ColumnItem className={`${className || ''} !gap-y-2.5`}>
				<p className='font-medium'>{heading}</p>
				{formattedItems?.length > 0 ? (
					<div className={`grid gap-x-5 gap-y-2.5 ${gridCols[cols]} ${mobileGridCols[mbCols]}`} tabIndex={0} aria-label={`Search Results for ${heading}`}>
						{formattedItems?.slice(0, itemLength).map((item, index) => {
							const { title, shortTitle, mainImage, subtitle, _type, isBundle } = item;
							return (
								<Link 
									to={getUriFromDocument(item)} 
									key={index} 
									className="w-full" 
									onClick={() => { toggleSearch(); trackItem(item);}} 
									title={`Search item: ${shortTitle || title}`}
									aria-label={`Search item: ${shortTitle || title}`}
								>
									<div
										className={`flex min-w-full gap-x-2.5 ${card
											? 'border-1 border-solid border-darker-gray rounded-xl overflow-hidden p-2'
											: ''
											}`}
									>
										{card && (
											<div className=" md:max-w-20 max-w-1/3 w-full aspect-[4/3] flex">
												<Image image={mainImage} width={80} height={60} className="rounded-xl !opacity-100" />
											</div>
										)}
										<div className="max-w-full w-full flex flex-col justify-center gap-y-2.5">
											<span className={`text-inherit w-fit ${card ? 'font-medium' : 'font-normal'}`}>
												{shortTitle || title}
												{_type === "shopifyProduct" ? ' - ' : ''}
												<span className='text-darker-gray font-normal'>
													{_type === "shopifyProduct" ? subtitle : ''}
												</span>
											</span>

											{/* {_type === "shopifyProduct" && 
												<span className='font-medium text-safety-green'>
													{price}
												</span>
											} */}
										</div>
									</div>
								</Link>
							);
						})}
					</div>
				) : (
					<div className={`h-full flex ${card ? 'items-center justify-center' : ''}`}>
						<span>No results for &ldquo;{query || ''}&rdquo;</span>
					</div>
				)}
			</ColumnItem>
			{showMoreLink && (
				<ColumnItem>
					{formattedItems?.length === 0 || hasDefaultItems ? (
						<Button
							externalLink="/collections/"
							theme="btn-transparent"
							width={{ desktopWidth: 'full', mobileWidth: 'full' }}
							className="animate-slideup"
						>
							View all {heading.toLowerCase()}
						</Button>
					) : (
						<Button
							externalLink={`/collections/?search=${itemIds?.join(',')}&query=${query}`}
							theme="btn-transparent"
							width={{ desktopWidth: 'full', mobileWidth: 'full' }}
							className="animate-slideup"
						>
							View all {formattedItems?.length} {heading.toLowerCase()}
						</Button>
					)}
				</ColumnItem>
			)}
		</>
	);
};

export default SearchItem;