import React, { createContext, useContext, useState } from "react";

const ProductQuickViewContext = createContext();

export const useProductQuickView = () => useContext(ProductQuickViewContext);

export const ProductQuickViewProvider = ({ children }) => {
  const [open, setQuickViewOpen] = useState(false);
  const [type, setQuickViewType] = useState('details');
  const [quickViewProduct, setQuickViewProduct] = useState(null);
  const [quickViewVariant, setQuickViewVariant] = useState(null);
  const [addedCrossSellProducts, setAddedCrossSellProducts] = useState([])
  const [quickViewProductList, setQuickViewProductList] = useState(null);


  return (
    <ProductQuickViewContext.Provider
      value={{
        open,
        setQuickViewOpen,
        type, 
        setQuickViewType,
        quickViewProduct,
        setQuickViewProduct,
        quickViewVariant,
        setQuickViewVariant,
        addedCrossSellProducts, 
        setAddedCrossSellProducts,
        quickViewProductList, 
        setQuickViewProductList,
      }}
    >
      {children}
    </ProductQuickViewContext.Provider>
  );
};