import { getRegionCurrency } from "@utils/region"
import { Buffer } from "buffer";

function formatPrice({ price = 0, currency = getRegionCurrency(), minimumFractionDigits = 0, parse = true }) {
  const appendCurrency = currency !== 'USD';
  const result = Intl.NumberFormat("en-US", {
    currency: currency,
    minimumFractionDigits,
    currencyDisplay: "narrowSymbol",
    trailingZeroDisplay: "auto",
    style: "currency",
  }).format(parse ? parseFloat(price) : price)
  

  if (appendCurrency) {
    return `${result} ${currency}`
  }

  return result
}

function formatPriceWithDecimals(price) {
  const number = +price
  
  if (Number.isInteger(number)) {
    return number.toString(); // No decimal places, return as is
  } else {
    return number.toFixed(2); // Format with two decimal places
  }
}

function getPricesFromPresentmentNodes(edges) {
  return {
    price: edges?.map(edge => edge.price),
    compareAtPrice: edges?.map(edge => edge.compareAtPrice)
  }
}

function getPriceFromVariantPresentmentPrices(
  prices = [],
  fallbackPrice = null,
) {
  const code = getRegionCurrency()
  const price = prices.find(varPrice => varPrice?.currencyCode === code)?.amount

  return price ? parseFloat(price) : fallbackPrice
}

function getPriceFromAccumulatedPresentmentPrices(
  prices = [],
  fallbackPrice = null,
  quantity = 1,
  crossSellProducts = [],
) {
  const code = getRegionCurrency()
  const crossSellProductsPrice = crossSellProducts?.map(crossSell => currencyOptionPrice(crossSell?.product) * crossSell?.quantity).reduce((x, y) => x + y, 0);
  const price = (prices.find(varPrice => varPrice?.currencyCode === code)?.amount * quantity) + crossSellProductsPrice;
  const fallback = quantity > 1 ? (fallbackPrice * quantity) + crossSellProductsPrice : fallbackPrice

  return price ? parseFloat(price) : fallback
}

function prices(productVariant) {
  return getPricesFromPresentmentNodes(
    productVariant?.presentmentPrices
  )
}

function currencyOptionPrice(currentVariant, quantity, crossSellProducts) {
  if (quantity && crossSellProducts) {
    return getPriceFromAccumulatedPresentmentPrices(
      prices(currentVariant)?.price,
      currentVariant?.price,
      quantity,
      crossSellProducts,
    )
  }

  return getPriceFromVariantPresentmentPrices(
    prices(currentVariant)?.price,
    currentVariant?.price
  )
}

function currencyOptionCompareAtPrice(currentVariant) {
  return getPriceFromVariantPresentmentPrices(
    prices(currentVariant)?.compareAtPrice,
    null
  )
}

function getPrice(minVariantPrice, currentVariant, raw = false, discountAmountFromCart = 0) {
  if (raw) return currencyOptionPrice(currentVariant) - discountAmountFromCart

  return formatPrice({
    price: currencyOptionPrice(currentVariant) - discountAmountFromCart,
    currency: getRegionCurrency() || minVariantPrice.currencyCode,
    minimumFractionDigits: Number.isInteger(currencyOptionPrice(currentVariant)) ? 0 : 2,
  })
}

function getAccumulatedPrice(minVariantPrice, currentVariant, quantity, crossSellProducts, raw = false, discountAmountFromCart = 0) {
  if (raw) {
    return currencyOptionPrice(currentVariant, quantity, crossSellProducts)
  }
  
  return formatPrice({
    price: currencyOptionPrice(currentVariant, quantity, crossSellProducts) - discountAmountFromCart,
    currency: getRegionCurrency() || minVariantPrice.currencyCode,
    minimumFractionDigits: Number.isInteger(currencyOptionPrice(currentVariant)) ? 0 : 2,
  })
}

function getCompareAtPrice(minVariantPrice, currentVariant, raw = false, discountAmountFromCart = 0) {
  if (raw) return currencyOptionCompareAtPrice(currentVariant) - discountAmountFromCart

  return currencyOptionCompareAtPrice(currentVariant) 
    && parseFloat(currencyOptionCompareAtPrice(currentVariant)) !== parseFloat(currencyOptionPrice(currentVariant))
    && formatPrice({
      price: currencyOptionCompareAtPrice(currentVariant) - discountAmountFromCart,
      currency: getRegionCurrency() || minVariantPrice.currencyCode,
      minimumFractionDigits: Number.isInteger(currencyOptionCompareAtPrice(currentVariant)) ? 0 : 2,
    })
}

function discountPercentage(currentVariant) {
  return 100 - (currencyOptionPrice(currentVariant) / currencyOptionCompareAtPrice(currentVariant)) * 100
}

function discountValue(currentVariant, minVariantPrice) {
  return formatPrice({
    price: currencyOptionCompareAtPrice(currentVariant) - currencyOptionPrice(currentVariant),
    currency: getRegionCurrency() || minVariantPrice.currencyCode,
  })
}

function getDiscountDisplay(discountDisplayPercentage, currentVariant, minVariantPrice) {
  return discountDisplayPercentage === 'true' 
    ? Math.round(discountPercentage(currentVariant).toFixed(2)) + '%' 
    : discountValue(currentVariant, minVariantPrice)
}

// function getDiscountAmountFromCart(cart, variant) {
//   const hasCartLevelDiscount = cart?.
//   const cartDiscountCode = cart?.discountCodes?.find(code => code.applicable)?.code;
//   const cartItem = cart?.lines?.find(line => line.merchandise.id === `gid://shopify/ProductVariant/${variant.shopifyId}`);
//   const hasVariantComponents = cart?.lines?.filter(line => variant?.variantComponents?.find(component => line.merchandise.id === Buffer.from(component.shopifyIdBase64Encoded, 'base64').toString())).slice(0, variant?.variantComponents?.length);

//   console.log(cart);
//   if (hasVariantComponents?.length > 0) {
//     const totalDiscountedAmount = hasVariantComponents.reduce((acc, component) => {
//       return acc + +component?.discountAllocations?.find(allocation => allocation.code === cartDiscountCode)?.discountedAmount?.amount;
//     }, 0);

//     return totalDiscountedAmount || 0;
//   } else if (cartItem) {
//     return +cartItem.discountAllocations?.find(allocation => allocation.code === cartDiscountCode)?.discountedAmount?.amount || 0;
//   }

//   return 0;
// }

export {
  getPrice, getDiscountDisplay, getCompareAtPrice, formatPrice,
  getPricesFromPresentmentNodes, getPriceFromVariantPresentmentPrices,
  prices, currencyOptionPrice, currencyOptionCompareAtPrice, getAccumulatedPrice,
  formatPriceWithDecimals, 
  // getDiscountAmountFromCart
}