import React from "react"
import { useUID } from "react-uid"
import { handleFileChange, validateFile } from  './helpers'
import FileCard from './file-card'

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Field = ({
  className,
  name,
  type,
  label,
  value,
  placeholder,
  register,
  required,
  errors,
  multiple,
  isGroup,
  selectedFiles,
  setSelectedFiles,
  onChange,
  onFocus,
  onBlur,
  rows,
}) => {
  const uid = useUID()

  if (!placeholder) {
    placeholder = label
  }

  const handleRef = ref => {
    if (selectedFiles?.length === 0 && ref) {
      ref.value = null;
    }

    if (register) {

      let params = {
        required
      }

      if (type === "email") {
        params = {
          ...params,
          pattern: {
            value: EMAIL_REGEX,
            message: "Invalid email address",
          },
        }
      }

      if (type === 'file') {
        params = {
          ...params,
          validate: () => validateFile(selectedFiles),
        }
      }

      register(ref, params)
    }
  }

  return (
    <>
    <div
      className={`flex ${isGroup ? 'flex-row ' : 'flex-col gap-y-2.5'} ${className || ''}`}
    >
      {label && <label
        htmlFor={uid}
        // className={`flex-shrink-0 absolute top-0 left-0 ml-4 pl-1 pr-1 flex-no-wrap -mt-3 bg-white ${styles.label}`}
        className={`${!isGroup ? 'text-sm leading-none font-medium font-owners' : ''}`}
      >
        <span className={`text-black ${errors[name] && !isGroup ? 'text-red-600' : ''}`}>{label}{required && !isGroup ? '*' : ''}</span>
      </label>}
      {type !== "textarea" && (
        <input
          id={uid}
          name={name}
          aria-label={label}
          type={type}
          className="outline-none w-[inherit]"
          // className="appearance-none w-full px-5 py-5 text-base leading-6 text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
          // className={`${type == 'date' ? 'date-input' : ''}`}
          placeholder={placeholder}
          ref={handleRef}
          multiple={type === 'file' && multiple}
          onChange={type === 'file' ? (e) => handleFileChange(e, setSelectedFiles) : onChange}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      {type === "textarea" && (
        <textarea
          id={uid}
          name={name}
          aria-label={label}
          rows={rows}
          // className="appearance-none w-full px-5 py-5 text-base leading-6 text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
          placeholder={placeholder}
          ref={handleRef}
        ></textarea>
      )}
      {/* {errors[name] && (
        <>
          <div className="absolute -mx-px left-0 bottom-0 right-0 h-2 bg-error"></div>
          <div className="absolute left-0 bottom-0 flex items-center transform translate-y-8">
            <svg
              width="12"
              height="12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.517 11L11 1M11.259 10.742l-10-9.484"
                stroke="#DF7E7E"
                strokeWidth="2"
              />
            </svg>
            <p className="text-error text-sm font-medium ml-2">
            {name === 'files' ? errors.files.message : 'This field is required'}
            </p>
          </div>
        </>
      )} */}
      {type === 'file' && (
        <div className="absolute top-[50%] right-2 translate-y-[-50%] select-none">{selectedFiles?.length} / 5</div>
      )}
    </div>
    {selectedFiles?.length > 0 && (
      <div className="flex flex-row gap-3 flex-wrap flex-grow relative mb-8 sm:mb-0 sm:col-span-2">
        {selectedFiles.map((file, index) => (
          <FileCard key={index} index={index} file={file} setSelectedFiles={setSelectedFiles} />
        ))}
      </div>
    )}
    </>
  )
}

export default Field

Field.defaultProps = {
  type: "text",
  required: false,
}
