import React from "react"
import { imageMobileOnly } from "@utils/general"
import { Link } from "gatsby"
import { getImageSource } from "./helpers"

const Image = ({ image, alt, objectFit, objectPosition, className, to, width, height, loading, ariaLabel, maxWidth, onClick }) => {
  if (!image && (!image?.mobile && !image?.desktop)) return null

  const imageObject = imageMobileOnly(image?.mobile, image?.desktop);
  const imageWidth = maxWidth && (maxWidth > width || !width) ? maxWidth : width;
  const imageHeight = maxWidth && (maxWidth > width || !width) ? null : height;
  const { src, srcSet, sizes } = getImageSource(image, imageObject, imageWidth, imageHeight);
  const sanityAsset = imageObject?.image?.asset || imageObject?.image[0]?.asset || image?.asset || image // Check for multiple Sanity Image types
  const filename = sanityAsset?.originalFilename?.replace(/\.(.*)$/, '').replace(/[^a-zA-Z0-9]+/g, ' ' )
  const shopifyImageDescription = image?.description || "Image illustrating relevant content from Diggs";
  const altText = alt || sanityAsset?.altText || shopifyImageDescription;

  const imgProps = {
    src,
    srcSet,
    sizes,
    title: sanityAsset.title || filename,
    className: `h-full w-full ${className}`,
    alt: altText || filename ,
    loading: loading,
    style: { //style for image tag
      objectFit: imageObject?.size || objectFit,
      objectPosition: imageObject?.position || objectPosition
    }
  }

  return to 
    ? <Link to={to} aria-label={ariaLabel} onClick={onClick} title={imgProps?.alt}>
        <img {...imgProps} />
      </Link>
    : <img {...imgProps} />
}

Image.defaultProps = {
  objectFit: "cover",
  objectPosition: "center",
  loading: "lazy",
  alt: '',
}

export default Image;