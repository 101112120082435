import { Buffer } from "buffer";

const base64ToUtf8 = (string) => {
  const isBase64 = (/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/).test(string) && string !== '' && string !== null;
  
  if (isBase64) {
    return Buffer.from(string, 'base64').toString('utf-8');
  }

  return string;
}

const hasShopifyPrefix = (string) => {
  if (string?.startsWith("gid://shopify/")) {
    const lastIndex = string.lastIndexOf("/");
    const result = string.substring(lastIndex + 1);
    return result
  }

  return string
}

export const checkShopifyId = (string) => {
  return hasShopifyPrefix(base64ToUtf8(string))
}

export const getProductList = (attributes, list) => {
  if (list) return list;

  if (attributes?.length > 0 && attributes?.find(attr => attr.key === '_list')) {
    return attributes.find(attr => attr.key === '_list').value;
  }

  return '/collections/'
}

export const getProductCategory = (attributes, category) => {
  if (typeof category === 'object' && category?.length > 0) {
    return category.join(', ')
  } else if (attributes?.length > 0 && attributes?.find(attr => attr.key === '_category')) {
    return attributes.find(attr => attr.key === '_category').value;
  }

  return 'All'
}

export const formatImageSrc = (src) => {
  if (src?.includes('https:')) {
    return src.replace('https:', '');
  }

  return src || '';
};

export const removeNullValues = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNullValues(obj[key]);
    } else if (typeof obj[key] === 'number' && key !== 'position') {
      obj[key] = obj[key]?.toString();
    }
  });
  return obj;
};


export const checkCompareAtPrice = (price, compareAtPrice) => {
  const displayedPrice = price?.amount ? price.amount : price;
  const displayedCompareAtPrice = compareAtPrice?.amount ? compareAtPrice.amount : compareAtPrice || '0.0';

  if (+displayedCompareAtPrice > +displayedPrice) {
    return displayedCompareAtPrice
  }

  return '0.0'
}