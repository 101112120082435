import React from 'react';

const Backdrop = ({ onClick, opened, zIndex }) => (
  <div
    className={`top-0 transition-all duration-500 ${opened ? `bg-soft-black fixed opacity-40 top-0 left-0 w-full h-screen ${zIndex || 'z-40'}` : ''}`}
    onClick={onClick}
  ></div>
);

export default Backdrop;
