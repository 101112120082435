import React, { createContext, useContext, useState } from "react";

const AddToCartContext = createContext()

export const useAddToCartContext = () => useContext(AddToCartContext);

export const AddToCartProvider = ({ children }) => {
  const initialStoreState = {
    currentVariantCraft: null,
    currentVariant: null,
    optionTitle: null,
    optionPrice: null,
    optionPriceRaw: null,
    optionCompareAtPrice: null,
    optionCompareAtPriceRaw: null,
    discountDisplay: null,
    couponDiscountDisplay: {
      coupon: null,
      amount: null
    },
    setCurrentVariant: () => { },
    setCurrentVariantCraft: () => { },
    setCurrentOptionTitle: () => { },
    setCurrentOption: () => { },
    variantImages: null, 
    setCurrentImagesCraft: () => { },
  }
  const [store, updateStore] = useState(initialStoreState)

  return (
    <AddToCartContext.Provider
      value={{
        store,
        setCurrentOption: ({
          optionTitle,
          optionPrice,
          optionPriceRaw,
          optionCompareAtPrice,
          optionCompareAtPriceRaw,
          discountDisplay,
          currentVariant,
          currentVariantCraft,
          couponDiscountDisplay,
        }) => {
          updateStore(prevState => {
            return {
              ...prevState,
              optionTitle,
              optionPrice,
              optionPriceRaw,
              optionCompareAtPrice,
              optionCompareAtPriceRaw,
              discountDisplay,
              currentVariant,
              currentVariantCraft,
              couponDiscountDisplay,
            }
          })
        },
      }}
    >
      {children}
    </AddToCartContext.Provider>
  )
}