import PropTypes from "prop-types"
import React from "react"
import { Link as GatsbyLink } from "gatsby"
import classnames from "classnames"
import * as styles from "./link.module.css"

const Link = ({ children, to, href, target, color, className, onClick, underline, ariaLabel }) => {
  // const underlineStyles = underline ? `border-b border-dark-navy ${styles.underline}` : null
  const underlineStyles = underline ? `${styles.underline}` : null
  const linkClasses = classnames(underlineStyles, 'hover:text-safety-green focus:outline-none transition ease-in-out duration-150 antialiased', className)
  const mailLink = href && href.includes('mailto:')
  const internalLink = href && (href.includes('#') || mailLink)

  if (internalLink) {
    target = '_self'
  }

  if (mailLink) {
    target = null
  }

  if (onClick && !to) {
    return <button className={linkClasses} onClick={onClick} aria-label={ariaLabel} >{children}</button>
  }

  if (to) {
    return <GatsbyLink className={linkClasses} to={to} onClick={onClick} aria-label={ariaLabel}>{children}</GatsbyLink>
  }

  return (
    <a href={href} className={linkClasses} target={target} aria-label={ariaLabel}>
      {children}
    </a>
  )
}

export const PlainLink = props => {
  const { href, to, children, ...rest } = props

  if (href) {
    return (
      <a href={href} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={to} {...rest}>
      {children}
    </GatsbyLink>
  )
}

export default Link

Link.defaultProps = {
  target: '_blank',
  color: 'text-dark-navy',
  underline: true,
}