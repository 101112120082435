/**
 * Layout component that queries for data
 * with Gatsby"s useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { setCookie } from "@analytics/cookie-utils"
import Header from "@components/header"
import Footer from "@components/footer"
// import NewsletterSignup from "@components/newsletter-signup"
import ShopifyContextProvider from "@context/shopify-context-provider"
import CartDrawer from "@src/components/cart/cart-drawer"
import Fatmedia from "@src/analytics/Fatmedia"
import Newsletter from "@components/newsletter"
import {
  TokiWallet,
  TokiScript,
  TokiNewsletterSignup,
} from "@components/widgets/toki"
import { StyluxProvider } from "@src/components/stylux"
import DisplayedAlert from "@src/components/alert/display-alert"
import useGlobalState from "@src/store"
import { listenKlaviyoForms } from "@src/utils/general"

const Layout = ({ props, children }) => {
  const setAlert = useGlobalState(state => state.setAlert);

  useEffect(() => {
    listenKlaviyoForms(setAlert);
    const currentUrl = new URL(window.location.href)
    if (currentUrl.searchParams.get("_country")) {
      setCookie("_geoCountry", currentUrl.searchParams.get("_country"))
    }
  }, [])

  return (
    <ShopifyContextProvider>
        <DisplayedAlert />
        <StyluxProvider>
          <a
            href="#main"
            className="absolute top-[-40px] left-0 z-[60] p-2 bg-gray-900 text-white text-center rounded-md no-underline focus:top-0"
          >
            Skip to content
          </a>
          <Fatmedia />
          <Header {...props?.pageContext?.pageFrame} />
          <main
            id="main"
            className={`text-soft-black ${
              props?.pageContext?.hasTopPadding
                ? "max-xs:pt-[148px] pt-[140px] md:pt-30"
                : "pt-16 pt-15 md:pt-large"
            }`}
          >
            {children}
            <Newsletter {...props?.pageContext?.newsletterBanner} />
          </main>
          <Footer {...props?.pageContext?.pageFrame?.footerData} />
          <CartDrawer />
          {/* <TokiNewsletterSignup /> */}
          <TokiWallet />
          <TokiScript />
        </StyluxProvider>
    </ShopifyContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
