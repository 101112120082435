// https://help.klaviyo.com/hc/en-us/articles/115005082927
// https://www.klaviyo.com/docs/getting-started
//

export default function klaviyoPlugin({ apiKey }) {
  return {
    /* Name is a required field for plugins */
    name: "klaviyo",
    /* Bootstrap runs when analytics starts */
    bootstrap: ({ payload, config, instance }) => {
      var __klKey = __klKey || apiKey
      ;(function () {
        var s = document.createElement("script")
        s.type = "text/javascript"
        s.async = true
        s.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${apiKey}`
        var y = document.getElementsByTagName("script")
        var x = y[y.length - 1]
        x.parentNode.insertBefore(s, x)
      })()
    },
    pageStart: ({ payload, config, instance }) => {
      // Fire custom logic before analytics.page() calls
    },
    pageEnd: ({ payload, config, instance }) => {
      console.log({ payload, config, instance })
      // Fire custom logic after analytics.page() calls
    },
    trackStart: ({ payload, config, instance }) => {
      // Fire custom logic before analytics.track() calls
    },
    trackEnd: ({ payload, config, instance }) => {
      const { event, properties } = payload
      window._learnq = window._learnq || [];

      if (event === 'viewedProduct') {
        const { shopItem, analyticsItem } = properties
        console.log({ event, properties })

        window._learnq.push([ 'track', "Viewed Product", shopItem ])
        window._learnq.push([ 'trackViewedItem', analyticsItem ])
      }
     
      if (event === 'addedToCart') {
        const { shopItem, analyticsItem } = properties
        window._learnq.push([ 'track', "Added to Cart", shopItem ])
        //window._learnq.push([ 'trackViewedItem', analyticsItem ])
      }

      if (event === 'backInStock') {
        const { email, variantId } = properties
        const klaviyoClient = Klaviyo.init(apiKey);
        const properties = {
          '$email': email,
          'Variant ID': variantId,
        };
        klaviyoClient.track('Back in Stock Notification', properties);
      }
    },
  }
} 

